import { CloseOutlined, ModeEditOutlined, SellOutlined } from '@mui/icons-material';
import {
  TitlebarActionIconContainer,
  TitlebarBreadCrumb,
  TitlebarBreadCrumbChild,
  TitlebarButton,
  TitlebarRoot,
  TitlebarSwitch,
  TitlebarTitle,
  TitlebarTitleButton,
  TitlebarTitleIcon,
} from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function UpsellPageTitlebar({
  title,
  isActive,
  onActiveChange,
  onTitleChange,
  onSaveClick,
  saveDisabled,
  helperText,
  category,
  setCategoryError,
}: UpsellPageTitlebarProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [editMode, setEditMode] = React.useState(false);
  const [prevTitle, setPrevTitle] = React.useState(title);

  const crumbs: TitlebarBreadCrumbChild[] = [
    {
      name: t('home'),
      href: '/',
    },
    {
      name: t('upsell_items'),
      href: '/upsell-items',
    },
  ];

  const handlerSaveClick = () => {
    if (!category || category === '') {
      setCategoryError(true);
      return;
    }

    onSaveClick();
    setEditMode(false);
  };

  return (
    <TitlebarRoot>
      <TitlebarTitleIcon>
        <SellOutlined />
      </TitlebarTitleIcon>

      <TitlebarBreadCrumb crumbs={crumbs} onClick={crumb => navigate(crumb.href)}>
        <TitlebarTitle
          title={title}
          editMode={editMode}
          helperText={helperText}
          onChange={e =>
            onTitleChange(
              (e as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>).target.value,
            )
          }
          data-testid='edit-offer-header'
        >
          {editMode ? (
            <TitlebarTitleButton
              onClick={() => {
                onTitleChange(prevTitle);
                setEditMode(false);
              }}
            >
              <CloseOutlined />
            </TitlebarTitleButton>
          ) : (
            <TitlebarTitleButton
              onClick={() => {
                setEditMode(true);
                setPrevTitle(title);
              }}
            >
              <ModeEditOutlined />
            </TitlebarTitleButton>
          )}
        </TitlebarTitle>
      </TitlebarBreadCrumb>

      <TitlebarActionIconContainer>
        <TitlebarSwitch
          tooltip={t(isActive ? 'active' : 'inactive')}
          checked={isActive}
          onChange={e => onActiveChange((e as React.ChangeEvent<HTMLInputElement>).target.checked)}
        />
      </TitlebarActionIconContainer>

      <TitlebarButton onClick={handlerSaveClick} disabled={saveDisabled}>
        {t('save')}
      </TitlebarButton>
    </TitlebarRoot>
  );
}

type UpsellPageTitlebarProps = {
  title: string;
  isActive: boolean;
  onActiveChange: (isActive: boolean) => void;
  onTitleChange: (title: string) => void;
  onSaveClick: () => void;
  deleteDisabled?: boolean;
  saveDisabled?: boolean;
  helperText?: string;
  category: string | null;
  setCategoryError: (error: boolean) => void;
};
