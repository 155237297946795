import { Stack, Typography } from '@mui/material';
import { TemplateDataType, TriggerDataType, WorkflowDataType } from '@operto/communications-shared';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import { TriggerType, TriggerTypeOption, useAutomate } from 'hooks/useAutomate';
import useTranslation from 'hooks/useTranslation';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import AutomateCreateWorkflowDiagram from './AutomateCreateWorkflowDiagram';
import AutomateCreateWorkflowTitlebar from './AutomateCreateWorkflowTitlebar';
import AutomateCreateWorkflowTriggerPanel from './AutomateCreateWorkflowTriggerPanel';
import AutomateCreateWorkflowUnits from './AutomateCreateWorkflowUnits';

export default function AutomateCreateWorkflowPage() {
  const { templateId } = useParams();
  const { workflows } = useAutomate();
  const { t } = useTranslation();

  const [workflow, setWorkflow] = useState<WorkflowDataType>({
    id: templateId,
    name: initialName,
    enabled: false,
    conditions: {
      selectedProperties: [],
    },
    triggers: [],
  });

  // TODO: remove this line once we use variable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isNameDirty, setIsNameDirty] = useState(false);
  const [errorName, setErrorName] = useState<string>();
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [assignToUnitsError, setAssignToUnitsError] = useState(false);
  const [selectedTrigger, setSelectedTrigger] = useState<number>();
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showTriggerError, setShowTriggerError] = useState([]);

  const invalidWorkflow = !!errorName;
  const selectedUnits = (workflow.conditions?.selectedProperties || []) as number[];
  const triggerCount = (workflow.triggers?.length || 0) as number;
  const hasTriggersNoSchedule = workflow.triggers.some(
    (trigger: TriggerDataType) =>
      trigger.type !== TriggerTypeOption.CHECK_IN && trigger.offset === 0,
  );
  const hasTriggersNoTemplate = workflow.triggers.some(
    (trigger: TriggerDataType) =>
      trigger.templates.sms.length === 0 && trigger.templates.email.length === 0,
  );
  const hasInvalidTriggers = hasTriggersNoSchedule || hasTriggersNoTemplate || !triggerCount;

  const validateNameChange = useCallback(
    (name: string) => {
      const trimmedName = name.trim();

      if (workflows?.some(c => c.name.trim() === trimmedName && c.id !== workflow.id)) {
        setErrorName(t('name_taken'));
        return false;
      }

      if (trimmedName.length === 0) {
        setErrorName(t('name_required'));
        return false;
      }

      if (trimmedName.length > MAX_NAME_LENGTH) {
        setErrorName(`${trimmedName.length}/${MAX_NAME_LENGTH}`);
        return false;
      }

      setErrorName(undefined);
      return true;
    },
    [workflows, workflow.id, t],
  );

  const handleSaveConfirm = async () => {
    // if failed validation, show errors and do not proceed
    if (!validateNameChange(workflow.name) || invalidWorkflow) {
      return;
    }

    setIsSaving(true);
  };

  const handleEnableWorkflowClick = async () => {
    if (selectedUnits.length === 0) {
      setAssignToUnitsError(true);
      return;
    }

    if (hasInvalidTriggers) {
      if (triggerCount) {
        setShowTriggerError(workflow.triggers.map((_: unknown, i: number) => i));
      }

      setShowErrorDialog(true);
      return;
    }

    setShowTriggerError([]);
    setAssignToUnitsError(false);
    setWorkflow((workflow: WorkflowDataType) => ({ ...workflow, enabled: !workflow.enabled }));
  };

  const handleSelectingUnits = useCallback((units: number[]) => {
    setWorkflow((workflow: WorkflowDataType) => ({
      ...workflow,
      enabled: false,
      conditions: {
        selectedProperties: units,
      },
    }));

    setAssignToUnitsError(false);
  }, []);

  const handleClickBefore = () => {
    setWorkflow((workflow: WorkflowDataType) => {
      setSelectedTrigger(workflow?.triggers?.length ?? 0);

      return {
        ...workflow,
        enabled: false,
        triggers: [
          ...workflow.triggers,
          {
            type: TriggerTypeOption.BEFORE_CHECK_IN,
            offset: 0,
            templates: {
              sms: [],
              email: [],
            },
          },
        ],
      };
    });
  };

  const handleClickedAfter = () => {
    setWorkflow((workflow: WorkflowDataType) => {
      setSelectedTrigger(workflow?.triggers?.length ?? 0);

      return {
        ...workflow,
        enabled: false,
        triggers: [
          ...workflow.triggers,
          {
            type: 'CHECK_IN',
            offset: 0,
            templates: {
              sms: [],
              email: [],
            },
          },
        ],
      };
    });
  };

  const handleDeleteTrigger = () => {
    setWorkflow((workflow: WorkflowDataType) => {
      const newTriggers = [...workflow.triggers];
      newTriggers.splice(selectedTrigger, 1);

      return {
        ...workflow,
        enabled: false,
        triggers: newTriggers,
      };
    });

    setShowTriggerError(showTriggerError.filter((i: number) => i !== selectedTrigger));
    setSelectedTrigger(undefined);
  };

  const handleOffsetChange = (offset: number) => {
    setWorkflow((workflow: WorkflowDataType) => {
      const newTriggers = [...workflow.triggers];
      newTriggers[selectedTrigger].offset = offset;

      return {
        ...workflow,
        enabled: false,
        triggers: newTriggers,
      };
    });
  };

  const handleTemplateChange = (template: TemplateDataType) => {
    setWorkflow((workflow: WorkflowDataType) => {
      const newTriggers = [...workflow.triggers];

      if (template.channelType === 'email') {
        newTriggers[selectedTrigger].templates.email = [template];
      }

      if (template.channelType === 'sms') {
        newTriggers[selectedTrigger].templates.sms = [template];
      }

      return {
        ...workflow,
        enabled: false,
        triggers: newTriggers,
      };
    });
  };

  const handleTypeChange = (newType: TriggerType) => {
    setWorkflow((workflow: WorkflowDataType) => {
      const newTriggers = [...workflow.triggers];
      newTriggers[selectedTrigger].type = newType;

      return {
        ...workflow,
        enabled: false,
        triggers: newTriggers,
      };
    });
  };

  return (
    <>
      <AutomateCreateWorkflowTitlebar
        title={workflow.name}
        onTitleChange={name => {
          setIsNameDirty(true);
          validateNameChange(name);
          setWorkflow({ ...workflow, name, enabled: false });
        }}
        onSaveClick={handleSaveConfirm}
        deleteDisabled={!workflow.id}
        helperText={errorName}
        editMode={editMode}
        setEditMode={setEditMode}
        onEnableWorkflowClick={handleEnableWorkflowClick}
        isSaving={isSaving}
        isWorkflowEnabled={workflow.enabled}
      />

      <AutomateCreateWorkflowUnits
        selectedUnits={selectedUnits}
        setSelectedUnits={handleSelectingUnits}
        assignToUnitsError={assignToUnitsError}
      />

      <Stack sx={{ flexDirection: 'row', gap: '24px' }}>
        <AutomateCreateWorkflowDiagram
          triggers={workflow.triggers}
          onClickBefore={handleClickBefore}
          onClickAfter={handleClickedAfter}
          hideAdd={triggerCount >= MAX_TRIGGERS}
          selectedCard={selectedTrigger}
          onCardClick={offset => setSelectedTrigger(offset)}
          showTriggerError={showTriggerError}
        />

        {selectedTrigger !== undefined && (
          <AutomateCreateWorkflowTriggerPanel
            onClose={() => setSelectedTrigger(undefined)}
            onDelete={handleDeleteTrigger}
            trigger={
              workflow.triggers.find((_: unknown, i: number) => i === selectedTrigger) || [
                {
                  offset: 0,
                },
              ]
            }
            onOffsetChange={handleOffsetChange}
            onTypeChange={handleTypeChange}
            onTemplateChange={handleTemplateChange}
            showErrorMessages={showTriggerError.some((i: number) => i === selectedTrigger)}
          />
        )}
      </Stack>

      <ConfirmDialog
        open={showErrorDialog}
        onSubmit={() => setShowErrorDialog(false)}
        title={
          <Typography variant='h3-700' sx={{ padding: '16px 24px' }}>
            {t('required_fields_are_missing')}
          </Typography>
        }
        submitButtonText={'OK'}
        contentStyles={{ padding: '8px 24px' }}
        actionsStyles={{ padding: '8px 16px 16px 8px' }}
      >
        <Typography variant='body-lg-400'>
          {t('please_update_your_flow_to_run_the_template')}
        </Typography>
      </ConfirmDialog>
    </>
  );
}

const initialName = 'Untitled flow';
const MAX_NAME_LENGTH = 50;
const MAX_TRIGGERS = 2;
