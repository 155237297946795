import { setupAppFeatures } from '@operto/app-features';
import { isProduction } from './constants';

const NEO_GATEWAY_V2_URL = process.env.REACT_APP_NEO_GATEWAY_V2_URL ?? '';
const NEO_API_KEY = process.env.REACT_APP_NEO_API_KEY ?? '';

const fetchOptions = {
  url: `${NEO_GATEWAY_V2_URL}/app-features/operations`,
  apiKey: NEO_API_KEY,
};

// declare your app features here
const appFeatures = {
  churnzero: {
    enabled: isProduction,
  },
  fullstory: {
    enabled: isProduction,
  },
  groups: {
    enabled: true,
  },
  tags: {
    enabled: true,
  },
  guestTerms: {
    enabled: true,
  },
  guestProfile: {
    enabled: true,
  },
  transactionsPage: {
    enabled: false,
  },
  upsells: {
    enabled: false,
  },
  communicationsPage: {
    enabled: !isProduction,
  },
  locks: {
    enabled: !isProduction,
  },
};

type AppFeatureFlags = keyof typeof appFeatures;

export const { useAppFeatures } = setupAppFeatures<AppFeatureFlags>(appFeatures, fetchOptions);
