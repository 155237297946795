import { createApiClient } from '@operto/upsells-shared';
import { ZodiosHooks } from '@zodios/react';

export const {
  useGetOfferDetails,
  useGetReservationOffers,
  useGetCompanyRequests,
  useGetGuestRequests,
  useCreateRequest,
  useGetRequestDetails,
  useGetCompanyOffers,
} = new ZodiosHooks('offers', createApiClient(process.env.REACT_APP_NEO_REST_API));
