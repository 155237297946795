import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Backdrop,
  Box,
  Button,
  CardMedia,
  IconButton,
  Paper,
  SxProps,
  Typography,
} from '@mui/material';
import { loadConnectAndInitialize, StripeConnectInstance } from '@stripe/connect-js';
import { ConnectComponentsProvider, ConnectPaymentDetails } from '@stripe/react-connect-js';
import { updateRequestStatus } from 'api/addOnAPI';
import { format, parseISO } from 'date-fns';
import { useGetCompanyContext, useGetTransactionDetails } from 'hooks/usePayments';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { useGetOfferDetails } from 'hooks/useUpsells';
import { logger } from 'lib/logger';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { userSelector } from 'user/state/userSelectors';
import { getStatusColor, Order, setColor } from './OrdersTable';

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const statusBoxStyle: SxProps = (status: string) => ({
  backgroundColor: getStatusColor(status),
  color: setColor(status),
  paddingVertical: '5px',
  paddingLeft: '8px',
  paddingRight: '8px',
  borderRadius: '5px',
  fontWeight: 600,
  fontSize: '10px',
});

type OrderDetailsSidebarProps = {
  order: Order;
  onClose: () => void;
  onUpdate: (orderId: Order['id']) => Promise<void>;
};

// TODO: use transaction details type in payments-shared
export type TransactionDetails = {
  transactionId: string;
  stripePaymentIntentId: string;
  stripeDestinationPaymentId: string;
  paymentMethodBrand: string;
  paymentMethodLastFour: string;
};

const OrderDetailsSidebar = ({ order, onClose, onUpdate }: OrderDetailsSidebarProps) => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState<StripeConnectInstance | null>(
    null,
  );
  const [transactionDetailsModalVisible, setTransactionDetailsModalVisible] = useState(false);
  const { accessToken } = useAppSelector(userSelector());

  const { data: offerDetails, isLoading: isOfferDetailsLoading } = useGetOfferDetails(
    {
      params: {
        companyId: order?.companyId,
        offerId: order?.offerId,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    {
      enabled: !!order?.companyId && !!order?.offerId,
    },
  );

  const { data: transactionDetails, isLoading: isTransactionDetailsLoading } =
    useGetTransactionDetails(
      {
        params: {
          companyId: order?.companyId,
          transactionId: order?.transactionId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      {
        enabled: !!order?.companyId && !!order?.transactionId,
      },
    );

  const { data: companyContext } = useGetCompanyContext(
    {
      params: { companyId: order?.companyId, withSession: true }, // TODO: fix withSession which isn't being passed to api
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    {
      enabled: !!order?.companyId,
    },
  );
  const [isUpdating, setUpdating] = useState(false);

  const { snackbar } = useSnackbar();
  const { t } = useTranslation();

  // TODO: use zod client from payments-shared
  const fetchAccountSession = useCallback(async () => {
    if (!!companyContext?.data) {
      return String(companyContext?.data?.stripe?.accountSessionSecret);
    } else {
      return '';
    }
  }, [companyContext]);

  const getStatusLabel = (status: string) => {
    if (!status) {
      return 'N/A';
    }
    if (status === 'approved' || status === 'completed') {
      return 'Purchased';
    }
    if (status === 'rejected' || status === 'declined') {
      return 'Declined';
    }
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  const onApproveClick = async () => {
    try {
      setUpdating(true);
      await updateRequestStatus(order.companyId, order.id, 'approved');
    } catch (error) {
      logger.error(error);
    } finally {
      await onUpdate(order.id);
      setUpdating(false);
    }
  };

  const onDeclineClick = async () => {
    try {
      setUpdating(true);
      await updateRequestStatus(order.companyId, order.id, 'rejected');
    } catch (error) {
      logger.error(error);
    } finally {
      await onUpdate(order.id);
      setUpdating(false);
    }
  };

  useEffect(() => {
    setStripeConnectInstance(
      loadConnectAndInitialize({
        publishableKey: publishableKey,
        fetchClientSecret: fetchAccountSession,
        appearance: {
          variables: {
            colorPrimary: '#228403', // optional appearance param,
          },
        },
      }),
    );
  }, [fetchAccountSession]);

  if (!order) return null;

  return (
    <Paper sx={containerStyle} data-testid='order-details'>
      <Backdrop sx={theme => ({ zIndex: theme.zIndex.drawer + 1 })} open={isUpdating}>
        <LoadingContainer loading />
      </Backdrop>
      <Box sx={headerStyle}>
        <Box>
          <Typography variant='body1'>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Typography>
        </Box>
        <Box sx={{ ...titleStyle, marginLeft: 1 }}>
          <Typography variant='subtitle2' data-testid='order-title'>
            {t('order_details')}
          </Typography>
          <Typography variant='h3' sx={{ fontWeight: 'bold' }} data-testid='order-name'>
            {order?.name ?? 'N/A'}
          </Typography>
        </Box>
      </Box>
      {order?.requestStatus === 'pending' && (
        <Box sx={sectionStyle}>
          <Button
            sx={controlButtonStyle}
            variant='contained'
            color='primary'
            onClick={onApproveClick}
            data-testid='order-approve-btn'
          >
            {t('approve')}
          </Button>
          <Button
            sx={controlButtonStyle}
            variant='outlined'
            color='error'
            onClick={onDeclineClick}
            data-testid='order-decline-btn'
          >
            {t('decline')}
          </Button>
        </Box>
      )}
      <Box sx={headerStyle}>
        <Typography variant='h6' data-testid='order-txt'>
          {t('order')}
        </Typography>
        <Box sx={statusBoxStyle(order?.requestStatus ?? '')} data-testid='order-status'>
          {getStatusLabel(order?.requestStatus)}
        </Box>
      </Box>

      <LoadingContainer loading={isOfferDetailsLoading}>
        <CardMedia
          data-testid='order-pic'
          component='img'
          height='140'
          image={order.imageUrl || 'https://via.placeholder.com/400x140'}
          sx={{ width: '100%', borderRadius: 2, marginBottom: 2 }}
        />
      </LoadingContainer>

      <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }} data-testid='order-id-txt'>
        {t('order_id')}
      </Typography>
      <Typography variant='body2' gutterBottom data-testid='order-id'>
        {order?.id ?? 'N/A'}
      </Typography>
      <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }} data-testid='order-unit-txt'>
        {t('unit')}
      </Typography>
      <Typography variant='body2' gutterBottom data-testid='order-unit'>
        {order?.unitName ?? 'N/A'}
      </Typography>
      <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }} data-testid='description-txt'>
        {t('description')}
      </Typography>

      <Typography variant='body2' gutterBottom data-testid='order-description'>
        {offerDetails?.data?.information?.description ?? '-'}
      </Typography>

      <Typography
        variant='subtitle1'
        sx={{ fontWeight: 'bold', marginTop: 2 }}
        data-testid='requested-on-txt'
      >
        {t('requested_on')}
      </Typography>
      <Typography variant='body2' gutterBottom data-testid='order-requested-date'>
        {order.requestedDate
          ? format(parseISO(order.requestedDate), "MMMM dd, yyyy 'at' h:mm a")
          : 'N/A'}
      </Typography>

      <Typography
        variant='subtitle1'
        sx={{ fontWeight: 'bold', marginTop: 2 }}
        data-testid='prefered-time-txt'
      >
        {t('selected_preferred_time')}
      </Typography>
      <Typography variant='body2' gutterBottom data-testid='order-prefered-time'>
        {order.preferredTime ?? 'N/A'}
      </Typography>

      <Typography
        variant='subtitle1'
        sx={{ fontWeight: 'bold', marginTop: 2 }}
        data-testid='guest-txt'
      >
        {t('guest')}
      </Typography>
      <Typography variant='body2' gutterBottom data-testid='order-guest-name'>
        {order?.guestName ?? 'N/A'}
      </Typography>

      <LoadingButton
        loading={transactionDetailsModalVisible || isTransactionDetailsLoading}
        variant='outlined'
        color='primary'
        onClick={() => {
          if (
            stripeConnectInstance !== null &&
            transactionDetails?.data?.stripeDestinationPaymentId !== undefined &&
            transactionDetails?.data?.stripeDestinationPaymentId !== ''
          ) {
            setTransactionDetailsModalVisible(true);
          } else {
            snackbar('No transaction details available for this request.');
            logger.info(
              `No transaction details for this request. transactionId: ${order.transactionId}, destinationPaymentId: ${transactionDetails?.data?.stripeDestinationPaymentId}`,
            );
          }
        }}
        sx={{ marginTop: 2, width: '100%' }}
        data-testid='view-details-btn'
      >
        {t('view_transaction_details')}
      </LoadingButton>

      {transactionDetailsModalVisible ? (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectPaymentDetails
            onClose={() => setTransactionDetailsModalVisible(false)}
            payment={transactionDetails.data?.stripeDestinationPaymentId}
          />
        </ConnectComponentsProvider>
      ) : null}
    </Paper>
  );
};

const containerStyle: SxProps = {
  width: 400,
  maxHeight: '100vh',
  overflow: 'auto',
  padding: 2,
  marginLeft: 2,
};

const headerStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 2,
};

const controlButtonStyle: SxProps = {
  width: '48%',
  textTransform: 'capitalize',
  fontWeight: 'bold',
};

const titleStyle: SxProps = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '100%',
};

const sectionStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 2,
};

export default OrderDetailsSidebar;
