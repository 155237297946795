import { Box, FormControlLabel, Radio, RadioGroup, Switch, Typography } from '@mui/material';
import { AddCard } from '@operto/ui-library';
import useTranslation from 'hooks/useTranslation';
import React, { ChangeEvent } from 'react';
import renderTextField from './TextInput';

interface CalendarPickerSectionProps {
  header: string;
  datePickerType: string;
  active: boolean;
  setDates: (status: string | string[]) => void;
  setdatePickerType: (status: string) => void;
  setActive: (active: boolean) => void;
}

export const CalendarPickerSection = ({
  header,
  datePickerType,
  active,
  setDates,
  setdatePickerType,
  setActive,
}: CalendarPickerSectionProps) => {
  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDates(event.target.value);
  };

  const handleDateSelectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setdatePickerType(event.target.value);
  };

  const { t } = useTranslation();

  return (
    <AddCard
      title={
        <Box display='flex' flexDirection='column' justifyContent='space-between'>
          <Typography variant='subtitle1'>{t('calendar_picker')}</Typography>
          <Typography variant='body2'>{t('body_text_for_calendar_picker')}</Typography>
        </Box>
      }
      action={<Switch checked={active} onChange={() => setActive(!active)} />}
    >
      {active && (
        <Box display='flex' flexDirection='column' gap={2}>
          {renderTextField(
            t('date_header'),
            t('dates'),
            header,
            handleDateChange,
            50,
            true,
            1,
            false,
            1,
            1,
          )}
          <RadioGroup value={datePickerType} onChange={handleDateSelectionChange}>
            <FormControlLabel
              value='singleDate'
              control={<Radio />}
              label={
                <Box display='flex' flexDirection='column' justifyContent='space-between'>
                  <Typography variant='subtitle1'>{t('single_date')}</Typography>
                  <Typography variant='body2'>
                    {t('allow_guests_to_select_a_single_date_only')}
                  </Typography>
                </Box>
              }
            />
            <FormControlLabel
              value='startEndDate'
              control={<Radio />}
              label={
                <Box display='flex' flexDirection='column' justifyContent='space-between'>
                  <Typography variant='subtitle1'>{t('date_range')}</Typography>
                  <Typography variant='body2'>
                    {t('allow_guests_to_select_a_start_and_end_date')}
                  </Typography>
                </Box>
              }
              sx={{ marginTop: '10px' }}
            />
          </RadioGroup>
        </Box>
      )}
    </AddCard>
  );
};
