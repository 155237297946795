import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import { companySelector } from 'company/state/companySelectors';
import { LockFilterType } from 'lock/lockType';
import React, { useCallback, useState } from 'react';

import { useAppSelector } from 'redux/hooks';
import {
  DeviceLockViewModel,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdLocksQuery,
} from 'services/novaApi';
import { TableCell } from 'ui-library/Components/table/TableCell';

import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import { formatRelativeToTimeZone } from 'helper/date';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';

export interface LocksTableProps {
  filterType: LockFilterType;
  menu?: React.ReactNode;
}

interface PagedResult<T> {
  data: T[];
  skip: number;
  take: number;
  total_count: number;
}

const INITIAL_STATE: PagedResult<DeviceLockViewModel> = {
  data: [],
  skip: 0,
  take: 100,
  total_count: 0,
};

const LocksTable = ({ menu }: LocksTableProps) => {
  const company = useAppSelector(companySelector());
  const companyId = company?.id;

  const [pagination, setPagination] = useState({
    pageNum: INITIAL_STATE.skip,
    numPerPage: INITIAL_STATE.take,
    keyword: '',
  });

  const {
    data: locks = INITIAL_STATE,
    isLoading,
    isFetching,
  } = useGetAccessCompatibilityOnboardingByLegacyCompanyIdLocksQuery({
    legacyCompanyId: companyId,
    skip: pagination.pageNum,
    take: pagination.numPerPage,
  });

  const handleFetch = useCallback((pageNum: number, numPerPage: number, searchValue?: string) => {
    setPagination({ pageNum, numPerPage, keyword: searchValue || '' });
  }, []);

  const renderAccountInfo = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    return (
      <TableCell title={row.provider_account_name} description={row.provider_account_user_email} />
    );
  };

  const renderLockName = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    return <TableCell title={row.lock_friendly_name} description={row.lock_legacy_id} />;
  };

  const renderPropertyName = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    if (row.unit_id) {
      return <TableCell title={`${row.unit_id} - ${row.unit_name}`} />;
    }
    // TODO: return the filter dropdown here
  };

  const lastActivityRenderer = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    if (row.last_synced_at === null) return '';
    return <Text.BodySmall>{formatRelativeToTimeZone(row.last_synced_at, 'UTC')}</Text.BodySmall>;
  };

  //TODO: Add Unit dropdown

  // const tabItems = {
  //   label: 'Assign Unit',
  //   childItems: availableUnits,
  //   value: 0, // Dummy value, adjust as needed
  // };

  // const renderUnitDropdown = () => (
  //   <DropDownMenu
  //     item={tabItems}
  //     handleClick={handleUnitChange}
  //     setTabIndex={() => {}}
  //     active={true}
  //     tabItemsStyle={{ padding: '8px 0' }}
  //   />
  // );

  const renderConnectivity = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    return (
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        {row.is_online ? (
          <>
            <CloudDoneOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', marginRight: 1 }} />
          </>
        ) : (
          <>
            <CloudOffOutlinedIcon sx={{ color: 'rgba(211, 47, 47, 1)', marginRight: 1 }} />
          </>
        )}
        <TableCell title={row.is_online ? 'Online' : 'Offline'} />
      </div>
    );
  };

  const columnsDef: GridColDef<DeviceLockViewModel>[] = [
    {
      field: 'provider_account_name',
      headerName: 'Account',
      flex: 1,
      renderCell: renderAccountInfo,
    },
    {
      field: 'lock_friendly_name',
      headerName: 'Lock',
      flex: 1,
      renderCell: renderLockName,
    },
    {
      field: 'unit_id',
      headerName: 'Unit',
      flex: 1,
      renderCell: renderPropertyName,
    },
    {
      field: 'is_online',
      headerName: 'Connectivity',
      flex: 1,
      renderCell: renderConnectivity,
    },
    {
      field: 'last_synced_at',
      headerName: 'Last Synced',
      flex: 1,
      renderCell: lastActivityRenderer,
    },
  ];

  return (
    <PaginatedTable
      enableToolbar
      title={menu}
      rows={locks.data}
      rowCount={locks.total_count}
      loading={isLoading || isFetching}
      onFetch={handleFetch}
      columns={columnsDef}
      getRowId={row => row.lock_legacy_id}
      sortingMode='server'
    />
  );
};

export default LocksTable;
