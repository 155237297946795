import { ArrowDropDown, Check, Search } from '@mui/icons-material';
import { Box, InputAdornment, Menu, MenuItem, TextField } from '@mui/material';
import { theme } from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React, { useState } from 'react';

export default function ListSelector({
  label,
  placeholder,
  list,
  value,
  onChange,
  errorText,
}: ListSelectorProps) {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const filteredList = list.filter(row =>
    row.name.toLowerCase().includes(searchText.toLowerCase()),
  );

  return (
    <Box sx={rootContainerStyles}>
      <TextField
        fullWidth
        value={value?.name ?? ''}
        label={label}
        placeholder={placeholder}
        InputLabelProps={{ shrink: open || !!value?.name }}
        sx={[textFieldStyle, errorText && textFieldErrorStyles]}
        FormHelperTextProps={{
          sx: { ...(errorText ? helperTextStyles : {}) },
        }}
        onClick={e => setAnchorEl(e.currentTarget)}
        helperText={errorText}
        InputProps={{
          readOnly: true,
          sx: { cursor: 'default' },
          endAdornment: (
            <InputAdornment position='end'>
              <ArrowDropDown />
            </InputAdornment>
          ),
        }}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={menuPaperProps}
        MenuListProps={{ sx: { padding: 0, flex: 1 } }}
        onKeyDownCapture={event => {
          event.stopPropagation();
        }}
      >
        <Box sx={{ padding: '24px' }}>
          <TextField
            autoFocus
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            placeholder={t('search')}
            sx={{ margin: 0 }}
            InputProps={{
              sx: { borderRadius: '32px' },
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {filteredList.map(row => {
          const isSelected = value?.id === row.id;

          return (
            <MenuItem
              key={row.id}
              onClick={() => {
                onChange(row);
                setAnchorEl(null);
              }}
              sx={{ height: '42px', justifyContent: 'space-between' }}
              selected={isSelected}
            >
              {row.name} {isSelected && <Check />}
            </MenuItem>
          );
        })}

        {filteredList.length === 0 && (
          <MenuItem sx={{ height: '42px', justifyContent: 'center', marginBottom: '24px' }}>
            {t('no_results_found')}
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
}

type rowType = { id: string; name: string };

type ListSelectorProps = {
  label: string;
  placeholder?: string;
  list: rowType[];
  value?: rowType;
  onChange: (selectedRow: rowType) => void;
  errorText?: string;
};

const rootContainerStyles = {
  width: '100%',
  backgroundColor: '#fff',
};

const textFieldStyle = {
  '&.MuiFormControl-root': {
    margin: 0,
  },
};

const menuPaperProps = {
  sx: {
    display: 'flex',
    width: '452px',
    borderRadius: '12px',
    '&>ul>div>div': { display: 'flex', flexDirection: 'column', gap: '16px' },
    '&>ul>div>div>div': { margin: 0 },
  },
};

const helperTextStyles = { color: theme.palette.error.main };

const textFieldErrorStyles = {
  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
      borderWidth: '2px',
    },
    '& .MuiInputLabel-outlined': {
      borderColor: theme.palette.error.main,
      borderWidth: '2px',
      '&.Mui-focused': {
        borderColor: theme.palette.error.main,
        borderWidth: '2px',
      },
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
        borderWidth: '2px',
      },
    },
    '&:hover:not(.Mui-focused)': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
        borderWidth: '2px',
      },
    },
  },
  '& .MuiInputLabel-outlined': {
    color: theme.palette.error.main,
    '&.Mui-focused': {
      color: theme.palette.error.main,
    },
  },
};
