import { Avatar, Box, Button, Switch, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { paletteStyles } from '@operto/ui';
import { UpsellDetailsType } from '@operto/upsells-shared';
import { getCompanyOffers, getOfferByID, getUnitsGroups, updateOffer } from 'api/addOnAPI';
import { companySelector } from 'company/state/companySelectors';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { useGetCompanyOffers } from 'hooks/useUpsells';
import { logger } from 'lib/logger';
import { TabName } from 'Pages/Marketplace/UpsellPage';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import { userSelector } from 'user/state/userSelectors';

type UpsellsTableProps = {
  title: string | React.ReactNode;
};

export type Unit = {
  id: number;
  name: string;
};

export type Group = {
  id: number;
  name: string;
};

const UpsellsTable = ({ title }: UpsellsTableProps) => {
  const navigate = useNavigate();
  const company = useAppSelector(companySelector());
  const { snackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState<UpsellDetailsType[]>([]);
  const [unitNames, setUnitNames] = useState<Unit[]>([]);
  const { t } = useTranslation();
  const { palette } = paletteStyles;
  const { accessToken } = useAppSelector(userSelector());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, isLoading, isError, error } = useGetCompanyOffers({
    params: { companyId: company?.id },
    enabled: !!company?.id,
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const updateOfferActive = (upsellId: string, isActive: boolean) => async () => {
    try {
      setLoading(true);
      const result = await getOfferByID(company?.id, upsellId);
      const data = result?.data?.data;
      data.isActive = isActive;
      const response = await updateOffer(company?.id, data);

      if (response.status === 200) {
        snackbar(t('changes_saved'));
        fetchOffers();
      } else {
        const errorMessage = `Failed to save offer, status: ${response.status}`;
        logger.error(`errorMessage: ${errorMessage}, responseBody: ${response.data.body}`);
        snackbar(errorMessage);
      }
    } catch (error) {
      logger.error(`Failed to update offer, ${error}`);
      snackbar(t('failed_to_update_offer'));
    }
  };

  const fetchOffers = useCallback(async () => {
    try {
      setLoading(true);
      const offersData = await getCompanyOffers(company?.id);
      const offers = offersData?.data?.data;

      setOffers(offers);
      setLoading(false);

      const unitsGroupsData = await getUnitsGroups(company?.id);
      const companyUnitsGroups = unitsGroupsData?.data?.data;

      const unitsMap = new Map(companyUnitsGroups.units?.map((unit: Unit) => [unit.id, unit.name]));

      offers.forEach((upsell: UpsellDetailsType) => {
        setUnitNames(
          upsell?.units?.map((unitId: string) => {
            const unitIdAsNumber = Number(unitId);
            const unitName = unitsMap.get(unitIdAsNumber) || 'Unknown';
            return { id: Number(unitIdAsNumber), name: unitName as string };
          }),
        );
      });
    } catch (error) {
      logger.error(`Error fetching upsell offers: ${error}`);
    }
  }, [company?.id]);

  const handleSearch = useCallback(
    (pageNum: number, numPerPage: number, searchString?: string) => {
      if (!searchString?.length) {
        return setFilteredOffers(offers);
      }
      const lowerCaseSearchString = searchString.toLowerCase();
      const filteredOffers = offers?.filter((upsell: UpsellDetailsType) => {
        const isActiveInput = lowerCaseSearchString === 'active';
        const isInactiveInput = lowerCaseSearchString === 'inactive';
        const matchesActiveStatus = isActiveInput
          ? upsell.isActive
          : isInactiveInput
          ? !upsell.isActive
          : false;

        return (
          upsell?.name?.toLowerCase().includes(lowerCaseSearchString) ||
          upsell?.information?.category?.toLowerCase().includes(lowerCaseSearchString) ||
          (unitNames.some(
            (unit: Unit) =>
              unit?.name?.toLowerCase().includes(lowerCaseSearchString) ||
              unit?.id.toString().includes(lowerCaseSearchString),
          ) ??
            false) || // Safely check for unitsAssigned
          matchesActiveStatus
        );
      });

      setFilteredOffers(filteredOffers);
    },
    [offers, unitNames],
  );

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  const handleRowTitleClick = useCallback(
    (id: string, activeTab?: TabName) => {
      if (!isNaN(activeTab)) {
        navigate(`/upsell-items/${id}`, { state: { activeTab } });
      } else {
        navigate(`/upsell-items/${id}`);
      }
    },
    [navigate],
  );

  const renderCategory = ({ row }: GridRenderCellParams<string, UpsellDetailsType>) => {
    return <Box>{row?.information?.category}</Box>;
  };

  const renderUpsells = ({ row }: GridRenderCellParams<string, UpsellDetailsType>) => {
    return (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          width: '100%',
        }}
        onClick={() => handleRowTitleClick(row.id)}
      >
        <Avatar
          src={row.imageUrl}
          alt={row.name}
          variant='rounded'
          sx={{ marginRight: '10px', borderRadius: '8px' }}
        />
        <Box
          onClick={() => handleRowTitleClick(row.id)}
          style={{ cursor: 'pointer' }}
          data-testid='title-txt'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant='body2'
            sx={{
              color: palette.primary.main,
              fontWeight: 600,
            }}
          >
            {row.name}
          </Typography>
          <Typography variant='body2' color='textSecondary'>
            {row.information?.description}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderUnits = ({ row }: GridRenderCellParams<number, UpsellDetailsType>) => {
    return (
      <Button
        onClick={() => handleRowTitleClick(row.id, TabName.UnitAssignment)}
        data-testid='units-btn'
      >
        {row?.units?.length ?? 0}
      </Button>
    );
  };

  const renderGroups = ({ row }: GridRenderCellParams<number, UpsellDetailsType>) => {
    return (
      <Button
        onClick={() => handleRowTitleClick(row.id, TabName.UnitAssignment)}
        sx={{
          color: palette.text.primary,
        }}
        data-testid='unit-groups-btn'
      >
        {row?.groups?.length ?? 0}
      </Button>
    );
  };

  const renderPrice = ({ row }: GridRenderCellParams<UpsellDetailsType>) => {
    return (
      <Box
        sx={{
          color: palette.text.primary,
        }}
      >
        {row?.priceSticker}
      </Box>
    );
  };

  const renderSwitch = ({ row }: GridRenderCellParams<UpsellDetailsType>) => (
    <Switch
      checked={row.isActive}
      onClick={updateOfferActive(row.id, !row.isActive)}
      color='primary'
      data-testid='active-switch'
    />
  );

  const columnsDef: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Offer',
      renderCell: renderUpsells,
      flex: 4,
      sortable: true,
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 3,
      maxWidth: 190,
      sortable: false,
      renderCell: renderCategory,
    },
    {
      field: 'priceSticker',
      headerName: 'Price',
      renderCell: renderPrice,
      flex: 3,
      maxWidth: 150,
      align: 'right',
      sortable: false,
      headerAlign: 'right',
    },
    {
      field: 'units',
      headerName: 'Units',
      renderCell: renderUnits,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'groups',
      headerName: 'Unit groups',
      renderCell: renderGroups,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'isActive',
      headerName: 'Active',
      renderCell: renderSwitch,
      width: 100,
      sortable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  return (
    <PaginatedTable
      enableToolbar
      title={title}
      rows={filteredOffers}
      rowCount={filteredOffers?.length}
      columns={columnsDef}
      loading={loading}
      onFetch={handleSearch}
    />
  );
};

export default UpsellsTable;
