import { getCompanyInformation } from 'company/state/companyAction';
import { LockFilterType } from 'lock/lockType';
import React from 'react';
import { useAppDispatch } from 'redux/hooks';
import LocksTable from './LocksTable';

const LocksPage = () => {
  const dispatch = useAppDispatch();
  const [filterType] = React.useState(LockFilterType.ALL_LOCKS);

  React.useEffect(() => {
    dispatch(getCompanyInformation());
  }, [dispatch]);

  return <LocksTable filterType={filterType} />;
};

export default LocksPage;
