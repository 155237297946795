import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import { Chip, CircularProgress, Stack, SxProps, Typography } from '@mui/material';
import { Loading } from 'Common/Loading';
import { StatusChip } from 'Common/StatusChip';
import { useGuestTermsPreview } from 'Common/Tables/Guests/Components/GuestTermsCell';
import { companySelector } from 'company/state/companySelectors';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import fromUnixTime from 'date-fns/fromUnixTime';
import isValid from 'date-fns/isValid';
import { guestsByIdSelector } from 'guest/state/guestSelectors';
import { useAppFeatures } from 'lib/app-features';
import React, { Suspense } from 'react';
import { useAppSelector } from 'redux/hooks';
import AddCard from 'ui-library/Components/card/AdderCard';

type GuestTermsCardProps = {
  guestId: number;
};

export const GuestTermsCard = ({ guestId }: GuestTermsCardProps) => {
  const guest = useAppSelector(guestsByIdSelector(guestId));
  const company = useAppSelector(companySelector());
  const isCompleted = isValid(guest?.terms_agreed_at);
  const { isFeatureEnabled } = useAppFeatures();
  const { isFetching, fetchPdfUrl } = useGuestTermsPreview({
    guestId: guest?.registered_guest_id,
    reservationId: guest?.reservation_id,
  });

  const renderStatus = () => {
    return (
      <StatusChip
        icon={isCompleted ? <CheckCircleIcon sx={{ fontSize: 14 }} /> : null}
        label={isCompleted ? 'Completed' : 'Incomplete'}
        textColor={isCompleted ? '#216E4E' : '#00000099'}
        backgroundColor={isCompleted ? '#BBF3DA' : '#F1F2F5'}
      />
    );
  };

  if (!(isFeatureEnabled('guestTerms') && company?.newLoginEnabled)) {
    return null;
  }

  if (!isCompleted) {
    return (
      <Stack direction='row' sx={styles.incomplete}>
        <Typography variant='subtitle1'>Guest Terms</Typography>
        {renderStatus()}
      </Stack>
    );
  }

  return (
    <Suspense fallback={<Loading />} data-testid='guest-terms-card'>
      <AddCard title='Guest Terms' action={renderStatus()}>
        <Chip
          variant='outlined'
          label='Guest Terms'
          icon={
            isFetching ? (
              <CircularProgress size={16} style={{ marginLeft: 10 }} />
            ) : (
              <PictureAsPdf sx={styles.pdf} color='error' />
            )
          }
          onClick={fetchPdfUrl}
          disabled={isFetching}
        />
        <Typography sx={{ marginTop: 2 }}>
          {formatInTimeZone(
            fromUnixTime(guest.terms_agreed_at),
            guest.timezone,
            "'Issued on' MMMM d, yyyy, 'at' HH:mm a",
          )}
        </Typography>
      </AddCard>
    </Suspense>
  );
};

const styles: Record<string, SxProps> = {
  incomplete: {
    padding: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '0.5px solid',
    borderColor: 'divider',
    borderRadius: '16px',
    height: '60px',
    p: '16px',
    bgcolor: 'white',
  },
  pdf: {
    height: 24,
    width: 24,
    pl: 0.5,
  },
};
