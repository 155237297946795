import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import { ICode } from 'code/codeType';
import { guestCodesSelector } from 'code/state/guestCodesSelectors';
import { utcToTimeZone } from 'helper/date';
import { Property } from 'property/propertyType';
import { getProperty } from 'property/state/propertySelectors';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { SearchToolbarProps } from 'ui-library/Components/table/SearchToolbar';
import { Table } from 'ui-library/Components/table/Table';
import GuestCodeDropDown from './GuestCodeDropDown';

export interface GuestCodesContainerProps {
  searchToolbarProps?: SearchToolbarProps;
  loading?: boolean;
}

const GuestCodesContainer = ({ searchToolbarProps, loading }: GuestCodesContainerProps) => {
  const { propertyId } = useParams<{ propertyId: string }>();
  const pId = parseInt(propertyId, 10);
  const property: Property = useAppSelector(getProperty(pId));
  const timezone = property?.timezone || 'America/Vancouver';

  const guestCodes = useAppSelector(guestCodesSelector(pId));

  const renderActions = (params: GridRenderCellParams) => (
    <div>
      <GuestCodeDropDown
        reservationId={params.row.reservation_id}
        accessCodeId={params.row.id}
        propertyId={pId}
        checkin={params.row.valid_from}
        checkout={params.row.valid_until}
      />
    </div>
  );

  const renderDuration = (params: GridRenderCellParams) => {
    return (
      <Text.BodySmall>
        {utcToTimeZone(params.row.valid_from, timezone, 'yyyy-MM-dd')}
        {'  ->  '}
        {utcToTimeZone(params.row.valid_until, timezone, 'yyyy-MM-dd')}
      </Text.BodySmall>
    );
  };

  const columnsDef: GridColumns = [
    { field: 'name', headerName: 'Guest Name', flex: 3, sortable: true },
    {
      field: 'duration',
      headerName: 'Duration',
      renderCell: renderDuration,
      flex: 3,
      sortable: true,
    },
    {
      field: 'access_code',
      headerName: 'Access Code',
      sortable: false,
      flex: 2,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      renderCell: renderActions,
      flex: 2,
      type: 'number',
    },
  ];

  const data = guestCodes.filter(
    (row: ICode) =>
      row.name && row.name.toLowerCase().includes(searchToolbarProps?.value?.toLowerCase()),
  );

  return (
    <Table
      pagination
      loading={loading}
      sx={{ height: '400px' }}
      rows={data}
      columns={columnsDef}
      initialState={{ pinnedColumns: { right: ['actions'] } }}
      searchToolbarProps={searchToolbarProps}
    />
  );
};

export default GuestCodesContainer;
