import { Add } from '@mui/icons-material';
import { Box, Fab, Stack, Tooltip } from '@mui/material';
import { TemplateDataType, WorkflowDataType } from '@operto/communications-shared';
import { Text } from '@operto/ui';
import { TriggerType, TriggerTypeOption } from 'hooks/useAutomate';
import useTranslation from 'hooks/useTranslation';
import React, { Fragment } from 'react';
import AutomateCreateWorkflowTriggerCard from './AutomateCreateWorkflowTriggerCard';

export default function AutomateCreateWorkflowDiagram({
  selectedCard,
  onCardClick,
  triggers,
  hideAdd,
  onClickBefore,
  onClickAfter,
  showTriggerError,
}: AutomateCreateWorkflowDiagramProps) {
  const { t } = useTranslation();

  const localTriggers = (triggers?.map((trigger, i) => ({ ...trigger, index: i })) ||
    []) as localTriggerType[];

  const triggersBefore =
    localTriggers?.filter(trigger => trigger.type === TriggerTypeOption.BEFORE_CHECK_IN) || [];
  const triggersAt =
    localTriggers?.filter(trigger => trigger.type === TriggerTypeOption.CHECK_IN) || [];
  const triggersAfter =
    localTriggers?.filter(trigger => trigger.type === TriggerTypeOption.AFTER_CHECK_IN) || [];

  const hasAt = !!triggersAt.length;
  const hasAfter = !!triggersAfter.length;

  return (
    <Stack sx={rootContainerStyles}>
      <Stack sx={diagramContainerStyles}>
        {triggersBefore.map((trigger, i) => (
          <Fragment key={trigger.index}>
            {!!i && (
              <Box sx={typeContainerStyles}>
                <Box sx={horizontalLineStyles} />
              </Box>
            )}

            <AutomateCreateWorkflowTriggerCard
              offset={trigger.offset}
              title={t('trigger_before_check_in')}
              isSelected={selectedCard === trigger.index}
              onClick={() => onCardClick?.(trigger.index)}
              templates={trigger.templates}
              showErrorMessages={showTriggerError?.includes(trigger.index)}
              isAt={trigger.type === TriggerTypeOption.CHECK_IN}
            />
          </Fragment>
        ))}

        {!hideAdd && (
          <>
            {!!triggersBefore.length && (
              <Box sx={[typeContainerStyles, { height: '24px' }]}>
                <Box sx={[horizontalLineStyles, { height: '24px' }]} />
              </Box>
            )}

            <Tooltip title={t('add_trigger')} slotProps={tooltipSlotProps}>
              <Fab sx={fabStyles} onClick={onClickBefore}>
                <Add />
              </Fab>
            </Tooltip>
          </>
        )}

        <Box sx={typeContainerStyles}>
          <Box
            sx={[
              horizontalLineStyles,
              {
                height: !hideAdd || !!triggersBefore.length ? undefined : '24px',
                bottom: !hideAdd || !!triggersBefore.length ? undefined : 0,
              },
            ]}
          />

          <Text variant='h4-300' style={typeStyles}>
            {t('before')}
          </Text>
        </Box>

        <Box sx={mainProcessContainerStyles}>
          <Text variant='h3-700'>{t('reservation_check_in')}</Text>
        </Box>

        {hasAt && (
          <>
            <Box sx={typeContainerStyles}>
              <Box sx={horizontalLineStyles} />

              <Text variant='h4-300' style={typeStyles}>
                {t('at')}
              </Text>
            </Box>

            {triggersAt.map((trigger, i) => (
              <Fragment key={trigger.index}>
                {!!i && (
                  <Box sx={typeContainerStyles}>
                    <Box sx={horizontalLineStyles} />
                  </Box>
                )}

                <AutomateCreateWorkflowTriggerCard
                  offset={trigger.offset}
                  title={t('trigger_at_check_in')}
                  isSelected={selectedCard === trigger.index}
                  onClick={() => onCardClick?.(trigger.index)}
                  hideScheduled
                  templates={trigger.templates}
                  showErrorMessages={showTriggerError?.includes(trigger.index)}
                  isAt={trigger.type === TriggerTypeOption.CHECK_IN}
                />
              </Fragment>
            ))}
          </>
        )}

        {hasAfter && (
          <>
            <Box sx={typeContainerStyles}>
              <Box sx={horizontalLineStyles} />

              <Text variant='h4-300' style={typeStyles}>
                {t('after')}
              </Text>
            </Box>

            {triggersAfter.map((trigger, i) => (
              <Fragment key={trigger.index}>
                {!!i && (
                  <Box sx={typeContainerStyles}>
                    <Box sx={horizontalLineStyles} />
                  </Box>
                )}

                <AutomateCreateWorkflowTriggerCard
                  offset={trigger.offset}
                  title={t('trigger_after_check_in')}
                  isSelected={selectedCard === trigger.index}
                  onClick={() => onCardClick?.(trigger.index)}
                  templates={trigger.templates}
                  showErrorMessages={showTriggerError?.includes(trigger.index)}
                  isAt={trigger.type === TriggerTypeOption.CHECK_IN}
                />
              </Fragment>
            ))}
          </>
        )}

        {!hideAdd && (
          <>
            <Box sx={typeContainerStyles}>
              <Box sx={horizontalLineStyles} />

              <Text variant='h4-300' style={typeStyles}>
                {t('at_or_after')}
              </Text>
            </Box>

            {!hideAdd && (
              <Tooltip title={t('add_trigger')} slotProps={tooltipSlotProps}>
                <Fab sx={fabStyles} onClick={onClickAfter}>
                  <Add />
                </Fab>
              </Tooltip>
            )}
          </>
        )}
      </Stack>

      <Box sx={messageContainerStyles}>
        <Text variant='body-lg-400'>{t('you_can_add_two_event_triggers')}</Text>
      </Box>
    </Stack>
  );
}

type AutomateCreateWorkflowDiagramProps = {
  selectedCard?: number;
  onCardClick?: (offset: number) => void;
  triggers?: WorkflowDataType['triggers'][];
  hideAdd?: boolean;
  onClickBefore: () => void;
  onClickAfter: () => void;
  showTriggerError?: number[];
};

type localTriggerType = {
  index: number;
  offset: number;
  templates: TemplateDataType;
  type: TriggerType;
};

const rootContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  backgroundColor: '#EBEDF5',
  borderRadius: '16px',
  marginTop: '24px',
  marginBottom: '-8px',
  padding: '24px 0 24px 0',
  position: 'relative',
};

const diagramContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
};

const messageContainerStyles = {
  display: 'flex',
  paddingTop: '24px',
  paddingBottom: '24px',
  justifyContent: 'center',
  alignItems: 'center',
};

const fabStyles = {
  backgroundColor: '#fff',
};

const typeContainerStyles = {
  height: '78px',
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
};

const mainProcessContainerStyles = {
  width: '600px',
  display: 'flex',
  padding: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  border: '4px solid rgba(8, 22, 62, 0.23)',
  borderRadius: '28px',
};

const typeStyles = { backgroundColor: '#EBEDF5', zIndex: 1 };

const horizontalLineStyles = {
  borderRight: '2px solid black',
  height: '78px',
  position: 'absolute',
};

const tooltipSlotProps = {
  popper: { modifiers: [{ name: 'offset', options: { offset: [0, -8] } }] },
};
