import * as FullStory from '@fullstory/browser';
import {
  createChurnZeroProvider,
  createFullStoryProvider,
  setupAnalytics,
} from '@operto/analytics';
import { getStripeInfo } from 'api/payment-method';
import { IMember } from 'member/memberType';
import { getSelf } from 'member/state/memberSelectors';
import { useEffect, useState } from 'react';
import { isAdmin } from 'redux/actions/ui';
import { useAppSelector } from 'redux/hooks';
import { IChurnZeroInstance } from 'types/churnZero';
import { useAppFeatures } from './app-features';
import { logger } from './logger';

export let userStripeId: string | null = null;

export const getChurnZeroInstance = (): IChurnZeroInstance => {
  const { ChurnZero } = window as unknown as { ChurnZero: IChurnZeroInstance };
  return ChurnZero;
};

// this hook encapsulate all logic required to setup ChurnZero
const useChurnZero = (currentMember?: IMember) => {
  const { isFeatureEnabled } = useAppFeatures();
  const [stripeId, setStripeId] = useState<string>();

  useEffect(() => {
    const getStripeAsync = async () => {
      try {
        const {
          data: { data: stripe },
        } = await getStripeInfo();
        if (stripe?.id && stripe.id !== null) {
          setStripeId(stripe.id);
          userStripeId = stripe.id;
        }
      } catch (error) {
        logger.error(error);
      }
    };

    getStripeAsync();
  }, []);

  useEffect(() => {
    if (!isFeatureEnabled('churnzero') || !stripeId || isAdmin() || !currentMember?.email) {
      removeProvider('churnzero');
      return;
    }

    try {
      const provider = createChurnZeroProvider({
        url: process.env.REACT_APP_CHURNZERO_URL,
        appKey: process.env.REACT_APP_CHURNZERO_APP_KEY,
        accountExternalId: stripeId,
        contactExternalId: currentMember?.email,
      });
      addProvider(provider);
    } catch (error) {
      logger.error(error);
    }
  }, [currentMember?.email, isFeatureEnabled, stripeId]);
};

// this hook encapsulate all logic required to setup FullStory
const useFullStory = (currentMember?: IMember) => {
  const { isFeatureEnabled } = useAppFeatures();

  useEffect(() => {
    if (!FullStory.isInitialized() || !currentMember?.id) {
      return;
    }

    logger.debug('setting fullstory identity');
    FullStory.identify(currentMember?.id.toString(), {
      email: currentMember?.email,
      company_id: currentMember?.company_id,
      name: currentMember?.name,
      role: currentMember?.role,
      job_title: currentMember?.job_title,
      contact_number: currentMember?.contact_number,
    });
  }, [
    currentMember?.company_id,
    currentMember?.contact_number,
    currentMember?.email,
    currentMember?.id,
    currentMember?.job_title,
    currentMember?.name,
    currentMember?.role,
  ]);

  useEffect(() => {
    if (!isFeatureEnabled('fullstory') || isAdmin()) {
      removeProvider('fullstory');
      return;
    }

    try {
      const provider = createFullStoryProvider({ orgId: process.env.REACT_APP_FULLSTORY_ORG_ID });
      addProvider(provider);
    } catch (error) {
      logger.error(error);
    }
  }, [isFeatureEnabled]);
};

export const { trackEvent, addProvider, hasProvider, removeProvider, useTrackScreen } =
  setupAnalytics({
    appName: 'CONNECT',
  });

export const useAnalytics = () => {
  const currentMember: IMember = useAppSelector(getSelf());

  useChurnZero(currentMember);
  useFullStory(currentMember);
};
