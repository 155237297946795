import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { Badge, Box, Button, Chip, IconButton, SxProps } from '@mui/material';
import { useMedia } from '@operto/ui';
import SearchField from 'Common/TextField/SearchField';
import React, { useState } from 'react';

interface ICustomSearchAndFilterProps {
  searchValue: string;
  onSearchChange: (search: string) => void;
  selectedChips: string[];
  onSelectedChipsChange: (chips: string[]) => void;
  chipFilters: string[];
  showFullSearchBar: boolean;
  onSearchIconClick: (value: boolean) => void;
  showMessagesTitle: (value: boolean) => void;
}

const CustomSearchAndFilter = ({
  searchValue,
  onSearchChange,
  selectedChips,
  onSelectedChipsChange,
  chipFilters,
  showFullSearchBar,
  onSearchIconClick,
  showMessagesTitle,
}: ICustomSearchAndFilterProps) => {
  const [showChips, setShowChips] = useState(false);
  const { isTablet, isMobile } = useMedia();

  const handleChipClick = (chipName: string) => {
    if (!selectedChips.includes(chipName)) {
      onSelectedChipsChange([...selectedChips, chipName]);
      return;
    }

    onSelectedChipsChange(selectedChips.filter(chip => chip !== chipName));
  };

  const handleClearClick = () => {
    onSelectedChipsChange([]);
  };

  const handleIconButtonClick = () => {
    showMessagesTitle(false);
    setShowChips(true);
  };

  const handleXClick = () => {
    if (isTablet || (isMobile && !showFullSearchBar)) {
      showMessagesTitle(true);
    }
    setShowChips(false);
  };

  const handleSearchClick = () => {
    showMessagesTitle(false);
    onSearchIconClick(true);
  };

  const isChipSelected = (chip: string): boolean => {
    return selectedChips.includes(chip);
  };

  const isAnyChipSelected = selectedChips.length > 0;

  const getChipStyles = (chip: string): SxProps => {
    const isSelected = isChipSelected(chip);

    return {
      color: isSelected ? 'white' : 'text.primary',
      backgroundColor: isSelected ? 'primary.main' : 'white',
      borderColor: isSelected ? 'grey' : undefined,
      '&:hover': {
        color: (isSelected ? 'white' : 'text.primary') + '!important',
        backgroundColor: (isSelected ? 'primary.main' : 'white') + '!important',
      },
      '$clickable&:focus': {
        backgroundColor: isSelected ? '#155EFF14' : 'transparent',
        border: '1px solid #08163E3B',
      },
    };
  };

  return (
    <Box>
      {!showChips && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: showFullSearchBar ? 'space-between' : 'flex-end',
            alignItems: 'center',
          }}
        >
          {showFullSearchBar ? (
            <SearchField
              key='messenger-search'
              size={'small'}
              value={searchValue}
              onChange={event => onSearchChange(event.currentTarget.value)}
              fullWidth
            />
          ) : (
            <IconButton onClick={handleSearchClick} data-testid='messenger-search-icon'>
              <SearchIcon />
            </IconButton>
          )}

          <Box>
            <IconButton onClick={handleIconButtonClick} data-testid='messenger-filter-list'>
              <Badge
                badgeContent={selectedChips.length}
                color='primary'
                data-testid='messenger-filter-badge'
              >
                <FilterListIcon />
              </Badge>
            </IconButton>
          </Box>
        </Box>
      )}

      {showChips && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            {chipFilters.map((chip: string, index: number) => (
              <Chip
                key={index}
                label={chip}
                sx={getChipStyles(chip)}
                variant='outlined'
                onClick={() => handleChipClick(chip)}
                data-testid={`chip-${chip}`}
              />
            ))}
          </Box>
          <Box>
            {isAnyChipSelected && (
              <Button
                sx={{ textTransform: 'none' }}
                onClick={handleClearClick}
                data-testid='messenger-clear-button'
              >
                {selectedChips.length > 1 ? 'Clear all' : 'Clear'}
              </Button>
            )}
            <IconButton onClick={handleXClick} data-testid='messenger-close-icon'>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CustomSearchAndFilter;
