import { AccessTimeOutlined, AddOutlined } from '@mui/icons-material';
import { Box, Card, CardHeader, Divider, Stack } from '@mui/material';
import { TemplateDataType } from '@operto/communications-shared';
import { Text } from '@operto/ui';
import { getDaysHoursMinutesPrintFormat } from 'hooks/useAutomate';
import useTranslation from 'hooks/useTranslation';
import { EmailChannelIcon, SmsChannelIcon } from 'Pages/Communications/CommunicationsIcons';
import React from 'react';
import ErrorMessageBanner from './ErrorMessageBanner';

export default function AutomateCreateWorkflowTriggerCard({
  offset,
  isSelected,
  title,
  onClick,
  hideScheduled,
  templates,
  showErrorMessages,
  isAt,
}: AutomateCreateWorkflowTriggerCardProps) {
  const { t } = useTranslation();

  const offsetFormatted = getDaysHoursMinutesPrintFormat(offset);
  const hasEmailTemplate = templates?.email?.length > 0;
  const hasSmsTemplate = templates?.sms?.length > 0;
  const emailSubtitle = `${t('send')} '${templates?.email?.[0]?.name}'`;
  const smsSubtitle = `${t('send')} '${templates?.sms?.[0]?.name}'`;
  const hasError =
    showErrorMessages &&
    (!isAt
      ? !offsetFormatted || (!hasEmailTemplate && !hasSmsTemplate)
      : !hasEmailTemplate && !hasSmsTemplate);
  const errorMissingAll = !isAt && !offsetFormatted && !hasEmailTemplate && !hasSmsTemplate;
  const errorMessage = `${!isAt && !offsetFormatted ? t('schedule') : ''} ${
    errorMissingAll ? 'and' : ''
  } ${!hasEmailTemplate && !hasSmsTemplate ? t('template') : ''} ${t(
    errorMissingAll ? 'are' : 'is',
  )} ${t('required')}`.toLowerCase();

  return (
    <Card
      elevation={4}
      sx={[
        rootStyles,
        {
          border: hasError ? '2px solid #D32F2F' : isSelected ? '2px solid #155EFF' : undefined,
          cursor: onClick ? 'pointer' : 'default',
        },
      ]}
      onClick={onClick}
    >
      {hasError && (
        <CardHeader title={<ErrorMessageBanner message={errorMessage} />} sx={{ padding: 0 }} />
      )}

      <Text style={[headerStyles, { fontWeight: 500 }]}>{title}</Text>

      <Stack sx={flexContainerStyles}>
        {!hideScheduled && (
          <>
            <CardItem
              icon={offsetFormatted ? <AccessTimeOutlined /> : undefined}
              title={offsetFormatted || t('schedule')}
            />

            <Divider />
          </>
        )}

        {hasEmailTemplate && (
          <CardItem
            icon={<EmailChannelIcon />}
            title={t('email_template')}
            subtitle={emailSubtitle}
          />
        )}

        {hasSmsTemplate && (
          <CardItem icon={<SmsChannelIcon />} title={t('sms_template')} subtitle={smsSubtitle} />
        )}

        {(!hasEmailTemplate || !hasSmsTemplate) && <CardItem title={t('template')} />}
      </Stack>
    </Card>
  );
}

type AutomateCreateWorkflowTriggerCardProps = {
  offset: number;
  isSelected?: boolean;
  title: string;
  onClick?: () => void;
  hideScheduled?: boolean;
  templates: TemplateDataType;
  showErrorMessages?: boolean;
  isAt?: boolean;
};

const CardItem = ({
  icon,
  title,
  subtitle,
}: {
  icon?: React.ReactNode;
  title?: string;
  subtitle?: string;
}) => {
  return (
    <Stack direction='row' gap='16px' alignItems='center'>
      <Box sx={[iconStyles, icon ? iconDefinedStyles : iconUndefinedStyles]}>
        {icon ?? <AddOutlined />}
      </Box>

      <Stack>
        {title && <Text variant='h4-300'>{title}</Text>}
        {subtitle && <Text variant='h4-700'>{subtitle}</Text>}
      </Stack>
    </Stack>
  );
};

const iconStyles = {
  fontSize: '24px',
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const iconDefinedStyles = {
  borderRadius: '10px',
  backgroundColor: '#F3F3F6',
};

const iconUndefinedStyles = {
  borderRadius: '8px',
  border: '2px dashed rgba(0, 0, 0, 0.26)',
  backgroundColor: '#fff',
  color: 'rgba(0, 0, 0, 0.26)',
};

const rootStyles = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '28px',
  backgroundColor: '#fff',
  width: '600px',
};

const headerStyles = {
  backgroundColor: 'rgba(243, 243, 246, 1)',
  padding: '14px 16px',
};

const flexContainerStyles = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px',
};
