import { Loading } from 'Common/Loading';
import React, { useEffect, useRef } from 'react';
import {
  PaginatedTable,
  getSortModelByUrl,
  sortTableByUrl,
} from 'ui-library/Components/table/PaginatedTable';
import DeliveryLogsTitlebar from './DeliveryLogsTitlebar';
import useDeliveryLogFilters from './useDeliveryLogFilters';
import useDeliveryLogs from './useDeliveryLogs';
import useDeliveryLogsColumns from './useDeliveryLogsColumns';

const DeliveryLogsTable = () => {
  const firstLoad = useRef(true);
  const deliveryLogsFilters = useDeliveryLogFilters();
  const columns = useDeliveryLogsColumns();
  const {
    data: deliveryLogsData,
    isFetching,
    isLoading,
  } = useDeliveryLogs(deliveryLogsFilters.searchParams);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
    }
  }, []);

  if (firstLoad.current && isLoading) {
    return <Loading />;
  }

  return (
    <>
      <DeliveryLogsTitlebar />
      <PaginatedTable
        enableToolbar
        rows={deliveryLogsData ?? []}
        columns={columns}
        filtersToolbarProps={deliveryLogsFilters}
        onSortModelChange={model =>
          deliveryLogsFilters.onFilterChange(
            sortTableByUrl(model, deliveryLogsFilters.searchParams),
          )
        }
        sortModel={getSortModelByUrl(deliveryLogsFilters.searchParams)}
        loading={!deliveryLogsFilters.isSynched || (isFetching && !deliveryLogsData)}
      />
    </>
  );
};

export default DeliveryLogsTable;
