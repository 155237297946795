import CloseIcon from '@mui/icons-material/Close';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { Box, Chip, Divider, Popover, Stack, SxProps, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Tag } from '@operto/tags-shared';
import { trpc } from '@operto/trpc-client';
import { Text } from '@operto/ui';
import { SearchTextField } from 'Common/TextField/SearchTextField';
import { useAppFeatures } from 'lib/app-features';
import React, { useCallback, useRef, useState } from 'react';
import { Member } from 'redux/members/types';
import { Option, SelectableMenu } from 'ui-library/Components/menu/SelectableMenu';
import { PaginatedTable, PaginatedTableHandle } from 'ui-library/Components/table/PaginatedTable';
import {
  SelectionTableProps,
  searchTextFieldStyle,
} from 'ui-library/Components/table/SelectionTable';
import { TableCell } from 'ui-library/Components/table/TableCell';

export const renderMembers = ({ row }: GridRenderCellParams<unknown, Member>) => {
  return (
    <TableCell title={row.name} description={row.email} testId={`group-member-cell-${row.id}`} />
  );
};

const columnsDef: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Members',
    flex: 1,
    renderCell: renderMembers,
    sortable: false,
  },
];

type MembersSelectionTableProps = Omit<SelectionTableProps, 'rows' | 'columns' | 'onFetch'> & {
  rows: Member[];
  columns?: GridColDef[];
  tableInfo?: string;
};

export const MembersSelectionTable = ({
  rows: members,
  columns = columnsDef,
  tableInfo,
  ...rest
}: MembersSelectionTableProps) => {
  const [searchValue, setSearchValue] = useState<string>();
  const { data: companyTags, isLoading: isGetTagsLoading } = trpc.tags.getTags.useQuery();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const { isFeatureEnabled } = useAppFeatures();
  const paginatedTableRef = useRef<PaginatedTableHandle>(null);
  const chipRef = useRef();

  const filteredList = members.filter(member => {
    const searched = !searchValue || member.name?.toLowerCase().includes(searchValue.toLowerCase());

    if (selectedTags.length === 0) return searched;

    // select unique member Ids that are in the selected tags
    for (let i = 0; i < selectedTags.length; i++) {
      if (selectedTags[i].memberIds.includes(member.id)) {
        return searched;
      }
    }
  });

  const handleFetch = useCallback((_pageNum: number, _numPerPage: number, searchValue?: string) => {
    setSearchValue(searchValue);
  }, []);

  const handleTagChange = (checked: boolean, tag: Tag) => {
    if (checked) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      setSelectedTags(selectedTags.filter(t => t.id !== tag.id));
    }
  };

  const renderTableInfo = () => {
    return (
      <>
        <Typography variant='subtitle-lg-600' sx={{ padding: '16px' }}>
          {tableInfo}
        </Typography>
        <Divider />
      </>
    );
  };

  const renderFilter = () => {
    return (
      <>
        <TuneOutlinedIcon
          onClick={() => setIsFilterOpen(true)}
          ref={chipRef}
          sx={filterIconStyle}
          data-testid='filter-icon'
        />
        <Popover
          open={isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
          anchorEl={chipRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          sx={popoverStyle}
        >
          <Box sx={popoverHeaderStyle}>
            <Text.H4Bold>Tags</Text.H4Bold>
            <CloseIcon
              onClick={() => setIsFilterOpen(false)}
              sx={{ '&:hover': { cursor: 'pointer' } }}
            />
          </Box>
          <SelectableMenu
            onChange={handleTagChange}
            selectedOptions={selectedTags}
            options={
              (companyTags?.sort((a: Tag, b: Tag) => a.label.localeCompare(b.label)) ??
                []) as Option[] // requires array of 'Option' type but magically works coz Tag has label and id as well.
            }
            onClear={() => setSelectedTags([])}
            isLoading={isGetTagsLoading}
          />
        </Popover>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <>
        {tableInfo ? renderTableInfo() : undefined}
        <SearchTextField
          size='medium'
          label='Enter member name'
          sx={searchTextFieldStyle}
          value={paginatedTableRef.current?.searchValue ?? ''}
          onChange={paginatedTableRef.current?.handleSearch}
          InputProps={{ endAdornment: isFeatureEnabled('tags') ? renderFilter() : undefined }}
          disabled={rest.disableSearch}
        />
        <Stack direction='row' sx={tagChipsContainerStyle} data-testid='tag-filter-chips-wrapper'>
          {selectedTags
            .sort((a: Tag, b: Tag) => a.label.localeCompare(b.label))
            .map((tag, index) => (
              <Chip
                key={index}
                variant='outlined'
                label={tag.label}
                sx={tagChipStyle}
                onDelete={() => handleTagChange(false, tag)}
              />
            ))}
        </Stack>
      </>
    );
  };

  return (
    <PaginatedTable
      ref={paginatedTableRef}
      checkboxSelection
      keepNonExistentRowsSelected
      renderHeader={renderHeader}
      rows={filteredList}
      columns={columns}
      onFetch={handleFetch}
      sx={{ height: '100%' }}
      sortModel={[{ field: 'name', sort: 'asc' }]}
      onSelectionModelChange={rest.onSelectionChange}
      {...rest}
    />
  );
};

const tagChipsContainerStyle = {
  padding: '0 16px',
  overflowX: 'auto',
  paddingBottom: '5px',
};

const popoverStyle: SxProps = {
  width: '300px',
  overflow: 'initial',
  '& .MuiPopover-paper': {
    borderRadius: '12px',
    padding: '24px',
    minWidth: '320px',
    width: 'fit-content',
  },
};

const filterIconStyle: SxProps = {
  '&:hover': {
    cursor: 'pointer',
  },
};

const popoverHeaderStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
};

const tagChipStyle: SxProps = {
  color: 'primary.main',
  backgroundColor: 'primary.p8',
  borderColor: 'text.white',
  '& .MuiChip-deleteIcon': {
    color: '#155EFF80 !important',
  },
};
