import axios from 'axios';

const api = process.env.REACT_APP_API_URL;
const apiGatewayRestUrl = process.env.REACT_APP_NEO_REST_API;

export const postToken = (token: { id: string }, email: string, companyName: string) =>
  axios.post(`${api}/v2/payment-methods`, {
    stripeToken: token.id,
    email,
    companyName,
  });

export const getCompanyData = (companyId: string, withSession = false) =>
  axios.get(
    `${apiGatewayRestUrl}/payments/companies/${companyId}?withSession=${
      withSession ? 'true' : 'false'
    }`,
  );

export const getTransactionDetails = (companyId: string, transactionId: string) => {
  const url = `${apiGatewayRestUrl}/payments/companies/${companyId}/transactions/${transactionId}`;
  return axios.get(url);
};

export const createStripeConnectAccount = (companyId: string) =>
  axios.post(`${apiGatewayRestUrl}/payments/stripe-connect/account`, {
    companyId: companyId,
  });

export const createStripeConnectAccountLink = (id: string) =>
  axios.post(`${apiGatewayRestUrl}/payments/stripe-connect/account-link`, {
    accountId: id,
    type: 'account_onboarding',
  });

export const getStripeConnectAccountData = (companyId: string) =>
  axios.get(`${apiGatewayRestUrl}/payments/companies/${companyId}/stripe-connect-data`);

export const retrieveStripeConnectAccount = (stripeAccountId: string) =>
  axios.get(`${apiGatewayRestUrl}/payments/stripe-connect/account/${stripeAccountId}`);
