import CloseIcon from '@mui/icons-material/Close';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { Box, Chip, Divider, Popover, Stack, SxProps, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Tag } from '@operto/tags-shared';
import { Text } from '@operto/ui';
import { SearchTextField } from 'Common/TextField/SearchTextField';
import { useAppFeatures } from 'lib/app-features';
import React, { useCallback, useRef, useState } from 'react';
import { Group } from 'redux/groups/types';
import { Unit } from 'redux/units/types';
import { SelectableMenu } from 'ui-library/Components/menu/SelectableMenu';
import { PaginatedTable, PaginatedTableHandle } from 'ui-library/Components/table/PaginatedTable';
import {
  SelectionTableProps,
  searchTextFieldStyle,
} from 'ui-library/Components/table/SelectionTable';
import { TableCell } from 'ui-library/Components/table/TableCell';

export const renderUnits = ({ row }: GridRenderCellParams<unknown, Unit>) => {
  return <TableCell title={row.name} description={row.id} testId={`group-unit-cell-${row.id}`} />;
};

const columnsDef: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Units',
    flex: 1,
    renderCell: renderUnits,
    sortable: false,
  },
];

type UnitsSelectionTableProps = Omit<SelectionTableProps, 'rows' | 'columns' | 'onFetch'> & {
  rows: Unit[];
  columns?: GridColDef[];
  tableInfo?: string;
  groups?: Group[];
  groupsAssigned?: Tag[];
  updateGroupsFields?: (groups: Tag[]) => void;
};

export const UnitsSelectionTable = ({
  rows: units,
  tableInfo,
  groups,
  groupsAssigned,
  updateGroupsFields,
  columns = columnsDef,
  ...rest
}: UnitsSelectionTableProps) => {
  const [searchValue, setSearchValue] = useState<string>();
  const filteredList = units.filter(
    unit => !searchValue || unit.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { isFeatureEnabled } = useAppFeatures();
  const chipRef = useRef();
  const paginatedTableRef = useRef<PaginatedTableHandle>(null);

  const renderTableInfo = () => {
    return (
      <>
        <Typography variant='subtitle-lg-600' sx={{ padding: '16px' }}>
          {tableInfo}
        </Typography>
        <Divider />
      </>
    );
  };

  const handleFetch = useCallback((_pageNum: number, _numPerPage: number, searchValue?: string) => {
    setSearchValue(searchValue);
  }, []);

  const renderFilter = () => {
    return (
      <>
        <TuneOutlinedIcon
          onClick={() => setIsFilterOpen(true)}
          ref={chipRef}
          sx={filterIconStyle}
          data-testid='filter-icon'
        />
        <Popover
          open={isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
          anchorEl={chipRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          sx={popoverStyle}
        >
          <Box sx={popoverHeaderStyle}>
            <Text.H4Bold>Groups</Text.H4Bold>
            <CloseIcon
              onClick={() => setIsFilterOpen(false)}
              sx={{ '&:hover': { cursor: 'pointer' } }}
            />
          </Box>
          <SelectableMenu
            onChange={handleTagChange}
            selectedOptions={groupsAssigned}
            options={
              groups?.filter(Boolean).map(group => ({
                label: group.name,
                value: group.id,
                id: group.id,
              })) ?? []
            }
            onClear={() => updateGroupsFields([])}
            isLoading={!groups}
          />
        </Popover>
      </>
    );
  };

  const handleTagChange = (checked: boolean, group: Tag) => {
    if (checked) {
      updateGroupsFields([...groupsAssigned, { id: group.id, label: group.label }]);
    } else {
      updateGroupsFields(groupsAssigned.filter((g: Tag) => g.id !== group.id));
    }
  };

  const renderHeader = () => {
    return (
      <>
        {tableInfo ? renderTableInfo() : undefined}
        <SearchTextField
          size='medium'
          label='Enter unit name'
          sx={searchTextFieldStyle}
          value={paginatedTableRef.current?.searchValue ?? ''}
          onChange={paginatedTableRef.current?.handleSearch}
          InputProps={{ endAdornment: isFeatureEnabled('groups') ? renderFilter() : undefined }}
          disabled={rest.disableSearch}
        />
        <Stack direction='row' sx={groupChipsContainerStyle} data-testid='tag-filter-chips-wrapper'>
          {groupsAssigned
            ?.sort((a: Tag, b: Tag) => a.label.localeCompare(b.label))
            ?.map((tag: Tag, index: number) => (
              <Chip
                key={index}
                variant='outlined'
                label={tag.label}
                sx={tagChipStyle}
                onDelete={() => handleTagChange(false, tag)}
              />
            ))}
        </Stack>
      </>
    );
  };

  return (
    <PaginatedTable
      ref={paginatedTableRef}
      checkboxSelection
      keepNonExistentRowsSelected
      renderHeader={renderHeader}
      rows={filteredList}
      columns={columns}
      onFetch={handleFetch}
      sx={{ height: '100%' }}
      sortModel={[{ field: 'name', sort: 'asc' }]}
      onSelectionModelChange={rest.onSelectionChange}
      {...rest}
    />
  );
};

const groupChipsContainerStyle = {
  padding: '0 16px',
  overflowX: 'auto',
  paddingBottom: '5px',
};

const popoverStyle: SxProps = {
  width: '300px',
  overflow: 'initial',
  '& .MuiPopover-paper': {
    borderRadius: '12px',
    padding: '24px',
    minWidth: '320px',
    width: 'fit-content',
  },
};

const filterIconStyle: SxProps = {
  '&:hover': {
    cursor: 'pointer',
  },
};

const popoverHeaderStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
};

const tagChipStyle: SxProps = {
  color: 'primary.main',
  backgroundColor: 'primary.p8',
  borderColor: 'text.white',
  '& .MuiChip-deleteIcon': {
    color: '#155EFF80 !important',
  },
};
