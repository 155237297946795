import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { SettingCard } from '@operto/ui-library';
import { companySelector } from 'company/state/companySelectors';
import { useAppFeatures } from 'lib/app-features';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isAdmin } from 'redux/actions/ui';
import { CustomText, useGuestPortal } from 'redux/guestportal';
import { useAppSelector } from 'redux/hooks';
import AddCard from 'ui-library/Components/card/AdderCard';
import ScriptDropDownMenu from 'ui-library/Components/drop-down/ScriptDropDownMenu';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { TextInsert } from 'ui-library/Helpers/textHelper';
import { userPermissionSelector } from 'user/state/userSelectors';
import { QRCodeMessagesForm } from './QRCodeMessagesForm';

type GlobalTextCardProps = {
  cardName: string;
  title?: string;
  message?: string;
  onTextChange: (text: { title?: string; message?: string }) => void;
  onSave: () => void;
  permission: boolean;
  action?: ReactNode;
  header?: ReactNode;
};
export const GlobalTextCard = ({
  cardName = '',
  title,
  message = '',
  onSave,
  onTextChange,
  permission,
  action,
  header,
}: GlobalTextCardProps) => {
  const welcomeTitleRef = useRef<{ selectionEnd: number }>();
  const welcomeMessageRef = useRef<{ selectionEnd: number }>();
  const [currentCursorPos, setCurrentCursorPos] = useState(0);

  return (
    <AddCard
      title={`${cardName} Message`}
      action={action}
      footerChildren={
        <Button
          variant='contained'
          color='primary'
          data-testid='save-button'
          startIcon={<SaveIcon />}
          onClick={() => {
            onSave();
          }}
          disabled={!permission}
        >
          Save
        </Button>
      }
    >
      {header}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {title !== undefined && (
            <Stack direction='row' alignItems='flex-start'>
              <TextField
                inputRef={welcomeTitleRef}
                onBlur={() => setCurrentCursorPos(welcomeTitleRef.current?.selectionEnd ?? 0)}
                label={`${cardName} Title`}
                placeholder={`Enter ${cardName.toLowerCase()} title`}
                variant='outlined'
                margin='normal'
                fullWidth
                style={{ paddingRight: '10px' }}
                value={title || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onTextChange({
                    message,
                    title: e.target.value,
                  })
                }
                disabled={!permission}
              />
              <ScriptDropDownMenu
                marginTop={8}
                onTextInsert={(text: string) => {
                  onTextChange({
                    message,
                    title: TextInsert(title, text, currentCursorPos),
                  });
                }}
              />
            </Stack>
          )}
          <Stack direction='row' alignItems='flex-start'>
            <TextField
              label={`${cardName} Message`}
              inputRef={welcomeMessageRef}
              placeholder={`Enter ${cardName.toLowerCase()} message`}
              variant='outlined'
              onBlur={() =>
                setCurrentCursorPos(welcomeMessageRef.current?.selectionEnd ?? undefined)
              }
              margin='normal'
              rows={8}
              fullWidth
              style={{ paddingRight: '10px' }}
              multiline
              value={message || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onTextChange({
                  message: e.target.value,
                  title,
                })
              }
              disabled={!permission}
            />
            <ScriptDropDownMenu
              marginTop={8}
              onTextInsert={(text: string) => {
                onTextChange({
                  title,
                  message: TextInsert(message, text, currentCursorPos),
                });
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </AddCard>
  );
};

const Loading = () => {
  return (
    <SettingCard title='Loading...'>
      <LoadingContainer loading>
        <Grid container spacing={2} height='250px' />
      </LoadingContainer>
    </SettingCard>
  );
};

export const GlobalText = ({ visible }: { visible: boolean }) => {
  const {
    fetchCustomText,
    fetchPropertyCustomText,
    setCustomText,
    setPropertyCustomText,
    customText,
  } = useGuestPortal();
  const { propertyId } = useParams();
  const [convertedText, setConvertedText] = React.useState<CustomText>();
  const permission = useAppSelector(userPermissionSelector());
  const company = useAppSelector(companySelector());
  const { isFeatureEnabled } = useAppFeatures();

  const onSave = () => {
    if (propertyId) {
      setPropertyCustomText(propertyId, convertedText);
    } else {
      setCustomText(convertedText);
    }
  };

  useEffect(() => {
    setConvertedText(customText);
  }, [customText]);

  useEffect(() => {
    if (propertyId) {
      fetchPropertyCustomText(Number(propertyId));
    } else {
      fetchCustomText();
    }
  }, [propertyId, fetchCustomText, fetchPropertyCustomText]);

  if (!visible) {
    return null;
  }

  if (!convertedText || !('isCustom' in convertedText)) {
    return <Loading />;
  }

  return (
    <Box>
      <GlobalTextCard
        cardName='Welcome'
        title={convertedText.welcomeTitle ?? ''}
        message={convertedText.welcomeMessage}
        onTextChange={value =>
          setConvertedText({
            ...(convertedText as CustomText),
            welcomeTitle: value.title as string,
            welcomeMessage: value.message as string,
          })
        }
        onSave={onSave}
        permission={permission}
      />
      <GlobalTextCard
        cardName='Goodbye'
        title={convertedText.goodbyeTitle ?? ''}
        message={convertedText.goodbyeMessage}
        onTextChange={value =>
          setConvertedText({
            ...(convertedText as CustomText),
            goodbyeTitle: value.title as string,
            goodbyeMessage: value.message as string,
          })
        }
        onSave={onSave}
        permission={permission}
      />
      <GlobalTextCard
        cardName='Terms and Conditions'
        message={convertedText.termsData.message ?? convertedText.termsAndCondition}
        onTextChange={value =>
          setConvertedText({
            ...convertedText,
            termsAndCondition: value.message as string,
            termsData: {
              ...convertedText.termsData,
              message: value.message as string,
            },
          })
        }
        onSave={onSave}
        permission={permission}
        action={
          isFeatureEnabled('guestTerms') &&
          company.newLoginEnabled && (
            <Switch
              checked={convertedText.termsData.enabled}
              onChange={({ target }) =>
                setConvertedText(prev => ({
                  ...prev,
                  termsData: { ...prev.termsData, enabled: target.checked },
                }))
              }
            />
          )
        }
        header={
          isFeatureEnabled('guestTerms') &&
          company.newLoginEnabled && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={convertedText.termsData.signatureEnabled}
                  onChange={({ target }) =>
                    setConvertedText(prev => ({
                      ...prev,
                      termsData: { ...prev.termsData, signatureEnabled: target.checked },
                    }))
                  }
                />
              }
              label='Ask guest to electronically sign this agreement'
            />
          )
        }
      />
      {
        // NOTE: this file is called GlobalText but for some reason it is used for property as well?
        !propertyId && isAdmin() && (
          <>
            <QRCodeMessagesForm
              name='Scanning QR code outside of Guest Portal'
              header='This message would show when guests scan the QR code without opening the guest portal'
              title={
                customText?.qrCodeWelcomeTitle?.length
                  ? customText?.qrCodeWelcomeTitle
                  : 'Thank you for visiting us'
              }
              message={
                customText?.qrCodeWelcomeMessage?.length
                  ? customText?.qrCodeWelcomeMessage
                  : 'Have you already booked with us? If so, please open the link that we sent you. If not, please talk with frontdesk.'
              }
              buttons={customText?.qrCodeWelcomeButtons}
              onSave={({ title, message, buttons }) => {
                setCustomText({
                  ...customText,
                  qrCodeWelcomeTitle: title,
                  qrCodeWelcomeMessage: message,
                  qrCodeWelcomeButtons: buttons,
                });
              }}
            />
            <QRCodeMessagesForm
              name='Scanning QR code to Check-in'
              header='This message would show when guests scan the QR code with the guest portal'
              message={
                customText?.qrCodeCheckinMessage?.length
                  ? customText?.qrCodeCheckinMessage
                  : 'There is QR code located inside the property, scan to check-in'
              }
              buttons={customText?.qrCodeCheckinButtons}
              onSave={({ message, buttons }) => {
                setCustomText({
                  ...customText,
                  qrCodeCheckinMessage: message,
                  qrCodeCheckinButtons: buttons,
                });
              }}
            />
          </>
        )
      }
    </Box>
  );
};

export default GlobalText;
