import { Grid } from '@mui/material';
import React from 'react';
import { useGetGroupsQuery } from 'redux/groups/api-slice';
import { GroupsState } from 'redux/groups/types';
import { INITIAL_STATE } from 'redux/members/api-slice';
import { useGetUnitsQuery } from 'redux/units/api-slice';
import { UnitFilterType, UnitsState } from 'redux/units/types';
import { UpsellFields } from '../UpsellPage';
import { AssignUnitSection } from './TabsComponents/AssignUnit';

interface UnitProps {
  upsellFields: UpsellFields;
  updateUpsellFields: <K extends keyof UpsellFields>(field: K, value: UpsellFields[K]) => void;
  categoryError: boolean;
  setCategoryError: (error: boolean) => void;
}

export const Unit = ({ upsellFields, updateUpsellFields }: UnitProps) => {
  const { unitAssigned, groupsAssigned } = upsellFields;

  const updateUnitFields = (
    field: keyof UpsellFields['unitAssigned'],
    value: UpsellFields['unitAssigned'][keyof UpsellFields['unitAssigned']],
  ) => {
    updateUpsellFields('unitAssigned', { ...unitAssigned, [field]: value });
    updateUpsellFields(
      'units',
      value.map(unit => unit.toString()),
    );
  };

  const updateGroupsFields = (
    field: keyof UpsellFields['groupsAssigned'],
    value: UpsellFields['groupsAssigned'][keyof UpsellFields['groupsAssigned']],
  ) => {
    updateUpsellFields('groupsAssigned', { ...upsellFields.groupsAssigned, [field]: value });
    updateUpsellFields(
      'groups',
      value.map(group => group.toString()),
    );
  };

  const {
    data: { units } = INITIAL_STATE as unknown as UnitsState,
    isLoading,
    isFetching,
  } = useGetUnitsQuery({
    filterType: UnitFilterType.ALL_UNITS,
    numPerPage: 100000,
  });

  const { data: { groups } = INITIAL_STATE as unknown as GroupsState } = useGetGroupsQuery({
    filterType: UnitFilterType.GROUP,
    numPerPage: 100000,
  });

  return (
    <Grid item xs={12} md={12}>
      <AssignUnitSection
        isLoading={isLoading}
        isFetching={isFetching}
        unitAssigned={unitAssigned.units}
        updateUnitFields={updateUnitFields}
        groupsAssigned={groupsAssigned.groups}
        updateGroupsFields={updateGroupsFields}
        units={units}
        groups={groups}
      />
    </Grid>
  );
};
