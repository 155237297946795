import axios from 'axios';
import { UpsellFields } from 'Pages/Marketplace/UpsellPage';

const api = process.env.REACT_APP_NEO_REST_API;
export const getCompanyOffers = (companyId: number) => {
  const url = `${api}/upsells/companies/${companyId}/offers`;
  return axios.get(url);
};

export const getOffersAvability = (companyId: number) => {
  const url = `${api}/upsells/companies/${companyId}/offersAvailability`;
  return axios.get(url);
};

export const getUnitsGroups = (companyId: number) => {
  const url = `${api}/upsells/companies/${companyId}/units-groups`;
  return axios.get(url);
};

export const getOfferByID = (companyId: number, upsellId: string) => {
  const url = `${api}/upsells/companies/${companyId}/offers/${upsellId}`;
  return axios.get(url);
};

export const getOfferTemplateByID = (templateId: string) => {
  const url = `${api}/upsells/offerTemplate/${templateId}`;
  return axios.get(url);
};

export const updateOffer = (companyId: number, upsellDetails: UpsellFields) => {
  const url = `${api}/upsells/companies/${companyId}/offers/${upsellDetails.id}`;
  return axios.put(url, upsellDetails);
};

export const uploadFileUrl = (companyId: number) => {
  const url = `${api}/upsells/companies/${companyId}/media`;
  return url;
};

export const getCompanyRequests = (companyId: number) => {
  const url = `${api}/upsells/companies/${companyId}/requests`;
  return axios.get(url);
};

export const getCompanyRequest = (companyId: string, requestId: string) => {
  const url = `${api}/upsells/companies/${companyId}/requests/${requestId}`;
  return axios.get(url);
};

export const updateRequestStatus = (companyId: string, requestId: string, status: string) => {
  const url = `${api}/upsells/companies/${companyId}/requests/${requestId}?status=${status}`;
  return axios.put(url);
};

export const getOfferTemplates = () => {
  const url = `${api}/upsells/offerTemplateList`;
  return axios.get(url);
};
