import { Box } from '@mui/material';
import { getOfferTemplates } from 'api/addOnAPI';
import UpsellsTable from 'Common/Tables/Upsells/UpsellsTable';
import { logger } from 'lib/logger';
import React, { useCallback, useEffect, useState } from 'react';
import { UpsellTemplate } from 'redux/upsell-service/types';
import TabBar from 'ui-library/Components/tabBar/TabBar';
import { SearchToolbar } from 'ui-library/Components/table/SearchToolbar';
import UpsellItemsTitlebar from './UpsellItemsTitlebar';
import UpsellTemplateCard from './UpsellTemplate/UpsellTemplateCard';
import UpsellTemplatePage from './UpsellTemplate/UpsellTemplatePaqe';

export enum UpsellsTabType {
  OFFERS = 0,
  TEMPLATES = 1,
  SETTINGS = 2,
}

export const tabItems = [
  {
    label: 'Offers',
    value: UpsellsTabType.OFFERS,
    isDropDown: false,
  },
  { label: 'Template library', value: UpsellsTabType.TEMPLATES, isDropDown: false },
];

const UpsellItemsPage = () => {
  const [activeTab, setActiveTab] = useState(UpsellsTabType.OFFERS);
  const [offerTemplates, setOfferTemplates] = useState([]);

  const fetchOfferTemplates = useCallback(async () => {
    try {
      const result = await getOfferTemplates();
      setOfferTemplates(result.data.data);
    } catch (error) {
      logger.error('Error fetching template upsell list', error);
    }
  }, []);

  useEffect(() => {
    fetchOfferTemplates();
  }, [fetchOfferTemplates]);

  const handleTabChange = (index: number) => {
    switch (index) {
      case 1:
        setActiveTab(UpsellsTabType.TEMPLATES);
        break;
      default:
        setActiveTab(UpsellsTabType.OFFERS);
        break;
    }
  };

  return (
    <>
      <UpsellItemsTitlebar />
      {activeTab === UpsellsTabType.TEMPLATES && (
        <Box>
          <SearchToolbar
            title={
              <TabBar tabItems={tabItems} tabIndex={activeTab} onTabChange={handleTabChange} />
            }
            onChange={
              search => console.log(search)
              // Handle template library search
            }
          />
          {offerTemplates && (
            <UpsellTemplatePage>
              {offerTemplates?.map((child: UpsellTemplate) => (
                <UpsellTemplateCard
                  key={child.id}
                  id={child.id}
                  title={child.name}
                  description={child.description}
                  category={child.category}
                  image={child.imageUrl}
                  price={child.price}
                />
              ))}
            </UpsellTemplatePage>
          )}
        </Box>
      )}
      {activeTab === UpsellsTabType.OFFERS && (
        <UpsellsTable
          title={<TabBar tabItems={tabItems} tabIndex={activeTab} onTabChange={handleTabChange} />}
        />
      )}
    </>
  );
};

export default UpsellItemsPage;
