import { useFilterLocalStorage } from 'hooks/useFilterLocalStorage';
import { useSyncFilters } from 'hooks/useSyncFilters';
import { useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import { FilterGroup, SCHEDULED } from 'types/filter-type';
import { userSelector } from 'user/state/userSelectors';
import { UserState } from 'user/userTypes';

const filtersData: FilterGroup[] = [
  {
    groupName: 'scheduled',
    label: 'Scheduled date',
    options: [
      {
        filterType: 'datepicker',
        label: '',
        name: 'scheduled',
        singleSelect: false,
      },
    ],
  },
  {
    groupName: 'status',
    label: 'Status',
    options: [
      { filterType: 'checkbox', label: 'Delivered', name: 'delivered' },
      { filterType: 'checkbox', label: 'Failed to deliver', name: 'failed' },
    ],
  },
  {
    groupName: 'channel',
    label: 'Channels',
    options: [
      { filterType: 'checkbox', label: 'Email', name: 'email' },
      { filterType: 'checkbox', label: 'SMS', name: 'sms' },
    ],
  },
];

export default function useDeliveryLogFilters() {
  const loggedInMember: UserState = useAppSelector(userSelector());
  const { getFilters, setFilters } = useFilterLocalStorage();

  const keyStorage = `${loggedInMember.user.id}_delivery_logs`;

  const {
    syncStatus,
    searchParams,
    filterGroups = [],
    syncFilters,
    addFilterGroups,
    setSyncStatus,
  } = useSyncFilters({
    key: keyStorage,
    persistFilters: setFilters,
    hydrateFilters: getFilters,
  });

  const onFilterChange = (params: URLSearchParams) => {
    syncFilters(params);
  };

  const onFilterClear = (key: string) => {
    if (key === SCHEDULED) {
      searchParams.delete(`${key}_from`);
      searchParams.delete(`${key}_until`);
    } else {
      searchParams.delete(key);
    }
    syncFilters(searchParams);
  };

  const isClearableFilters = (key: string) => {
    if (key === SCHEDULED) {
      return (
        searchParams.get(`${key}_from`)?.length > 0 || searchParams.get(`${key}_until`)?.length > 0
      );
    }
    return searchParams.get(key)?.length > 0;
  };

  const updateChipLabel = (groupKey: string) => {
    let count = 0;
    let updatedLabel = '';
    const filterValue = searchParams.get(groupKey)?.split(',') ?? [];

    // find label based on filter value
    const selectedFilterGroup = filterGroups.find(
      filterGroup => filterGroup.groupName === groupKey,
    );

    if (groupKey === SCHEDULED) {
      const startValue = searchParams.get(`${groupKey}_from`);
      const endValue = searchParams.get(`${groupKey}_until`);

      if (startValue) {
        updatedLabel = startValue;
      }

      if (endValue && startValue !== endValue) {
        updatedLabel += ` to ${endValue}`;
      }
    } else {
      const selectedFilter = selectedFilterGroup?.options.find(
        option => option.name === filterValue[0],
      );

      if (filterValue.length != 0) {
        updatedLabel = selectedFilter.label;
      } else {
        updatedLabel = updatedLabel;
      }

      if (filterValue.length - 1 > 0) {
        count = filterValue.length - 1;
      } else {
        count = 0;
      }
    }

    return updatedLabel !== '' ? `${updatedLabel} ${count > 0 ? `+${count}` : ''}` : '';
  };

  useEffect(() => {
    if (syncStatus === 'unsynched') {
      setSyncStatus('synching');

      addFilterGroups(filtersData);

      syncFilters(getFilters(keyStorage));
    }
  }, [addFilterGroups, getFilters, keyStorage, setSyncStatus, syncFilters, syncStatus]);

  return {
    isSynched: syncStatus === 'synched',
    filterGroups,
    searchParams,
    isClearableFilters,
    onFilterChange,
    onFilterClear,
    updateChipLabel,
  };
}
