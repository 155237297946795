import { Box } from '@mui/material';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellCheckboxRenderer,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { Tag, getTagsByMemberId } from '@operto/tags-shared';
import { trpc } from '@operto/trpc-client';
import { featurePermissionByMemberRole } from 'helper/helper';
import { useAppFeatures } from 'lib/app-features';
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { INITIAL_STATE, useGetMembersQuery } from 'redux/members/api-slice';
import { MEMBERS_ROLES, Member, MemberFilterType } from 'redux/members/types';
import TableTooltip from 'ui-library/Components/table/TableTooltip';
import { currentUserSelector } from 'user/state/userSelectors';
import { MembersSelectionTable, renderMembers } from '../Common/MembersSelectionTable';
import { NamedChip } from '../Common/NamedChip';

type GroupsFormTabMembersProps = {
  memberIds: number[];
  onChange: (memberIds: number[]) => void;
};

export const GroupsFormTabMembers = ({ memberIds, onChange }: GroupsFormTabMembersProps) => {
  const { isFeatureEnabled } = useAppFeatures();
  const {
    data: { members } = INITIAL_STATE,
    isLoading,
    isFetching,
  } = useGetMembersQuery({
    filterType: MemberFilterType.ALL_MEMBERS,
    numPerPage: 100000,
  });

  const { data: companyTags = [] } = trpc.tags.getTags.useQuery();

  const user = useAppSelector(currentUserSelector());

  const isRowSelectable = (rowData: { row: Member }): boolean => {
    const { row: member } = rowData;
    //disable row if the scope doesn't include the role or any
    const allowedScope = featurePermissionByMemberRole(user?.role, member?.role, 'groups', 'edit');

    //you are allowed to edit yourself as a global manager, this is a bit more complex to be covered
    // in the simple permission setup, it will have to be a case covered in the fill permission system
    const allowIfSelfGlobal =
      user?.role === MEMBERS_ROLES.GLOBAL_MANAGER && user?.id === member?.id;

    return allowedScope || allowIfSelfGlobal;
  };

  const createColumnDef = (isRowSelectable: (params: GridCellParams) => boolean): GridColDef[] => {
    return [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        type: 'boolean',
        renderCell: params => {
          const selectable = isRowSelectable(params);
          return (
            <>
              {selectable ? (
                <GridCellCheckboxRenderer {...params} />
              ) : (
                <TableTooltip title='Edit restricted'>
                  <Box>
                    <GridCellCheckboxRenderer {...params} />
                  </Box>
                </TableTooltip>
              )}
            </>
          );
        },
      },
      {
        field: 'name',
        headerName: 'Members',
        flex: 1,
        renderCell: renderMembers,
        sortable: false,
      },
      isFeatureEnabled('tags') && {
        field: 'tags',
        headerName: 'Member Tags',
        flex: 1,
        sortable: false,
        renderCell: ({ row: member }: GridRenderCellParams<unknown, Member>) => {
          const memberTags = getTagsByMemberId(member.id, companyTags);
          return <NamedChip names={memberTags?.map((tag: Tag) => tag.label)} />;
        },
      },
    ];
  };

  return (
    <MembersSelectionTable
      loading={isLoading || isFetching}
      rows={members}
      isRowSelectable={isRowSelectable}
      selectionModel={memberIds}
      onSelectionChange={onChange}
      columns={createColumnDef(isRowSelectable)}
    />
  );
};
