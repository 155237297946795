import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { Tag } from '@operto/tags-shared';
import { trpc } from '@operto/trpc-client';
import { OpertoLogger } from 'Logger/logger';
import { getMemberProfilePicture, uploadMemberProfilePicture } from 'api/memberAPI';
import { useAppFeatures } from 'lib/app-features';
import { IMember } from 'member/memberType';
import React, { Suspense, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import AddCard from 'ui-library/Components/card/AdderCard';
import {
  FormTextField,
  FormTextFieldRegexEmail,
  FormTextFieldRegexPhone,
} from 'ui-library/Components/input/FormTextField';
import { MemberTagsField } from 'ui-library/Components/input/MemberTagsField';
import ImageEditor from 'ui-library/Components/misc/ImageEditor';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { FormHeader } from 'ui-library/Styled/sidebar';

type MemberDetailsData = Pick<
  IMember,
  | 'id'
  | 'name'
  | 'email'
  | 'contact_number'
  | 'profile_picture_url'
  | 'job_title'
  | 'member_type'
  | 'staff_type'
>;

export type MemberDetailsFormHandle = {
  submit: () => void;
  save: () => void;
  isValid: boolean;
  selectedTags: Tag[];
  updatedTags: Tag[];
};

export type MemberDetailsFormProps = {
  member?: MemberDetailsData;
  onSubmit: (member: MemberDetailsData) => void;
  onSave?: (member: MemberDetailsData) => void;
  memberTags?: Tag[];
  isNewMember?: boolean;
};

export const NEW_MEMBER_ID_PLACEHOLDER = -1;

export const MemberDetailsForm = React.forwardRef<MemberDetailsFormHandle, MemberDetailsFormProps>(
  function MemberDetailsForm({ member, onSubmit, onSave, memberTags, isNewMember }, ref) {
    const { isFeatureEnabled } = useAppFeatures();
    const dispatch = useAppDispatch();
    const [base64ProfileImage, setBase64ProfileImage] = useState('');
    const { data: companyTags = [] } = trpc.tags.getTags.useQuery();
    const [selectedTags, setSelectedTags] = useState<Tag[]>(memberTags || []);
    const [updatedTags, setUpdatedTags] = useState<Tag[]>([]);

    const tagOptions = useMemo(() => {
      return companyTags
        .filter((option: Tag) => !selectedTags?.find(tag => tag.label === option.label))
        .map((option: Tag) => option.label)
        .sort((a: string, b: string) => a.localeCompare(b));
    }, [companyTags, selectedTags]);

    const {
      handleSubmit,
      reset,
      watch,
      setValue,
      control,
      formState: { isValid },
    } = useForm<MemberDetailsData>({
      mode: 'all',
    });

    const handleImageEvents = async (image_base64: string) => {
      try {
        const response = await uploadMemberProfilePicture({ image_base64 });
        setValue('profile_picture_url', response.data.data.filename);
        setBase64ProfileImage(image_base64);
      } catch (error) {
        OpertoLogger.Log(error);

        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Error uploading profile picture image',
            variant: SnackbarVariant.ERROR,
          }),
        );
      }
    };

    const handleTagsChange = (event: React.SyntheticEvent, values: string[]) => {
      const trimmedValues = values.map(value => value.trim()).filter(value => value.length > 0);
      const inputValues = Array.from(new Set(trimmedValues.map(value => value.toLowerCase())));

      // selected companyTags
      const selectedTags: Tag[] = companyTags?.filter((tag: { label: string }) =>
        inputValues.includes(tag.label),
      );

      // newly added tags that are not in memberTags but in companyTags
      const addedTags = selectedTags
        .filter(tag => !memberTags?.includes(tag))
        .map(tag => ({
          ...tag,
          memberIds: [...tag.memberIds, member?.id || NEW_MEMBER_ID_PLACEHOLDER],
        }));

      // tags removed from memberTags
      const deletedTags = memberTags
        ? memberTags
            .filter((tag: Tag) => !selectedTags.includes(tag))
            .map(tag => ({
              ...tag,
              memberIds: tag.memberIds.filter((id: number) => id !== member?.id),
            }))
        : [];

      // newly created tags that is not in companyTags
      const newTags = inputValues
        .filter(value => !companyTags.map((tag: { label: string }) => tag.label).includes(value))
        .map(tag => ({
          label: tag,
          memberIds: [member?.id || NEW_MEMBER_ID_PLACEHOLDER],
        }));

      const updatedTags: Tag[] = [...addedTags, ...deletedTags, ...newTags];

      const onDisplayTags: Tag[] = [...selectedTags, ...newTags];

      setUpdatedTags(updatedTags);
      setSelectedTags(onDisplayTags);
    };

    useImperativeHandle(
      ref,
      () => ({
        submit: handleSubmit(onSubmit),
        save: handleSubmit(onSave),
        isValid,
        selectedTags,
        updatedTags,
      }),
      [handleSubmit, isValid, onSubmit, onSave, selectedTags, updatedTags],
    );

    useEffect(() => {
      const asyncSetDefaultMember = async () => {
        if (!member) {
          return;
        }

        reset(member);
        if (member.profile_picture_url?.length) {
          try {
            const response = await getMemberProfilePicture(member.id);
            const base64 = response.data?.data?.image_base64;
            if (base64) {
              setBase64ProfileImage(base64);
            }
          } catch (error) {
            OpertoLogger.Log(error);
          }
        }
      };

      asyncSetDefaultMember();
    }, [member, reset]);

    useEffect(() => {
      setSelectedTags(memberTags);
    }, [memberTags]);

    return (
      <>
        <AddCard title='Contact Details'>
          <FormHeader align='left'>Set this users primary contact information</FormHeader>
          <Grid container spacing={2}>
            <Grid item md={7} xs={12}>
              <FormTextField
                rules={{
                  required: {
                    value: true,
                    message: 'Name is required',
                  },
                }}
                field='name'
                label='Full Name'
                control={control}
              />
              <FormTextField
                rules={{
                  required: {
                    value: true,
                    message: 'Email address is required',
                  },
                  pattern: {
                    value: FormTextFieldRegexEmail,
                    message: 'Valid email address format is required',
                  },
                }}
                field='email'
                type='email'
                label='Email'
                disabled={!isNewMember}
                control={control}
              />
              <FormTextField
                rules={{
                  required: {
                    value: true,
                    message: 'Phone number is required',
                  },
                  pattern: {
                    value: FormTextFieldRegexPhone,
                    message: 'Valid phone number format is required',
                  },
                }}
                field='contact_number'
                type='tel'
                label='Phone'
                control={control}
              />
            </Grid>
            <Grid item container md={5} xs={12} alignItems='center' justifyContent='center'>
              <ImageEditor
                image={base64ProfileImage}
                onDrop={handleImageEvents}
                onSave={handleImageEvents}
                onDelete={() => {
                  setValue('profile_picture_url', '');
                  setBase64ProfileImage('');
                }}
              />
            </Grid>
          </Grid>
        </AddCard>
        <AddCard title='Job Details'>
          <FormHeader align='left'>Describe this user&apos;s job type</FormHeader>
          <FormTextField field='job_title' label='Job Title' control={control} />
          <FormControl fullWidth sx={{ mx: 0 }}>
            <InputLabel id='select-job-type-label'>Select Job Type</InputLabel>
            <Select
              label='Select Job Type'
              id='select-job-type'
              labelId='select-job-type-label'
              name='member_type'
              placeholder='Select members job type'
              onChange={event => setValue('member_type', event.target.value)}
              value={watch('member_type') ?? 'cleaner'}
              data-testid='member_type'
            >
              <MenuItem value='cleaner'>Cleaner</MenuItem>
              <MenuItem value='maintenance'>Maintenance</MenuItem>
              <MenuItem value='manager'>Manager</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mx: 0 }}>
            <InputLabel id='choose-team-status'>Choose Team Status</InputLabel>
            <Select
              label='Choose Team Status'
              id='choose-team-status'
              labelId='choose-team-status-label'
              name='staff_type'
              placeholder='Select members team status'
              onChange={event => setValue('staff_type', event.target.value)}
              value={watch('staff_type') ?? 'employee'}
              data-testid='staff_type'
            >
              <MenuItem value='employee'>Full Time Staff</MenuItem>
              <MenuItem value='contractor'>Contractor</MenuItem>
              <MenuItem value='part-time'>Part Time</MenuItem>
            </Select>
          </FormControl>
        </AddCard>
        {isFeatureEnabled('tags') && (
          <Suspense fallback={<LoadingContainer loading />}>
            <AddCard title='Member Tags'>
              <FormHeader align='left'>Add tags to this member</FormHeader>
              <MemberTagsField
                selectedTags={selectedTags}
                tagOptions={tagOptions}
                onTagsChange={handleTagsChange}
              />
            </AddCard>
          </Suspense>
        )}
      </>
    );
  },
);

export default MemberDetailsForm;
