import { Stack } from '@mui/material';
import { companySelector } from 'company/state/companySelectors';
import { useAppFeatures } from 'lib/app-features';
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import Cloudbeds from './Cloudbeds/Cloudbeds';
import GuestyIntegrationsCard from './Guesty/GuestyIntegrationsCard';
import { IntegrationsSections } from './IntegrationsSections';
import { StripeIntegrationCard } from './Stripe/StripeIntegrationCard';
import { StripeConnectIntegrationCard } from './StripeConnect/StripeConnectIntegrationCard';
import { YourKeyPlusIntegrationCard } from './YourKeyPlus/YourKeyPlusIntegrationCard';

const IntegrationsPage = () => {
  const company = useAppSelector(companySelector());
  const { isFeatureEnabled } = useAppFeatures();
  const upsellsEnabled = isFeatureEnabled('upsells', company.id);

  return (
    <Stack p={3}>
      <IntegrationsSections title='PMS'>
        <Cloudbeds />
        <GuestyIntegrationsCard />
      </IntegrationsSections>

      <IntegrationsSections title='Payment'>
        <StripeIntegrationCard />
        {upsellsEnabled && <StripeConnectIntegrationCard />}
      </IntegrationsSections>
      <IntegrationsSections title='Access'>
        <YourKeyPlusIntegrationCard />
      </IntegrationsSections>
    </Stack>
  );
};

export default IntegrationsPage;
