import { Avatar, Box, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getCompanyRequestList } from '@operto/upsells-shared';
import { getCompanyRequest } from 'api/addOnAPI';
import { companySelector } from 'company/state/companySelectors';
import { format } from 'date-fns';
import useTranslation from 'hooks/useTranslation';
import { useGetCompanyRequests } from 'hooks/useUpsells';
import { logger } from 'lib/logger';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import { userSelector } from 'user/state/userSelectors';
import OrderDetailsSidebar from './OrderDetailSidebar';

// TODO: move export to upsells-shared
export type Order = Awaited<ReturnType<typeof getCompanyRequestList>>[0] & {
  transactionId: string | undefined;
  companyId: string | undefined;
  offerId: string | undefined; // TODO: add these properties to requestList definition
  preferredTime: string | undefined;
  /** TODO Type Order.selectedQuantity, not implemented yet
   * @see {@link /src/Common/Tables/Orders/OrderDetailSidebar.tsx}
   * @line 238
   * */
  selectedQuantity?: number | null;
};

export const getStatusColor = (status: string) => {
  switch (status?.toLowerCase()) {
    case 'pending':
      return '#E4E965';
    case 'approved':
    case 'completed':
      return '#BCDFE4';
    case 'rejected':
    case 'declined':
      return '#676668';
    case 'canceled':
      return '#E7E3E4';
    default:
      return 'white';
  }
};

export const setColor = (status: string) => {
  switch (status?.toLowerCase()) {
    case 'rejected':
    case 'declined':
      return '#f5f5f5';
    default:
      return '#111111';
  }
};

const getDisplayStatus = (status: string) => {
  if (!status) {
    return 'N/A';
  }
  switch (status.toLowerCase()) {
    case 'approved':
    case 'completed':
      return 'Purchased';
    default:
      return status[0].toUpperCase() + status.slice(1);
  }
};

const OrdersTable = ({ title }: { title: string }) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const company = useAppSelector(companySelector());
  const { t } = useTranslation();
  const { accessToken } = useAppSelector(userSelector());
  const { data: companyRequests, isLoading } = useGetCompanyRequests(
    {
      params: { companyId: company.id },
      headers: { Authorization: `Bearer ${accessToken}` },
    },
    {
      enabled: !!company.id,
    },
  );

  const fetchOrder = useCallback(
    async (requestId: string) => {
      try {
        const { data: responseBody } = await getCompanyRequest('' + company.id, requestId);
        return responseBody.data;
      } catch (error) {
        logger.error('Error fetching order', error);
      }
    },
    [company.id],
  );

  const onUpdate = async (orderId: Order['id']) => {
    const freshOrder = await fetchOrder(orderId);
    const currentOrder: { [key: string]: unknown } = filteredOrders.find(o => o.id === orderId);
    for (const field of Object.getOwnPropertyNames(freshOrder)) {
      currentOrder[field] = freshOrder[field];
    }
    setFilteredOrders(Array.from(filteredOrders));
  };

  const handleSearch = useCallback(
    (pageNum: number, numPerPage: number, searchString?: string) => {
      if (!searchString?.length) {
        return setFilteredOrders(orders);
      }
      const lowerCaseSearchString = searchString.toLowerCase();
      const filteredOrders = orders.filter((order: Order) => {
        return (
          order.requestStatus?.toLowerCase()?.includes(lowerCaseSearchString) ||
          order.name?.toLowerCase()?.includes(lowerCaseSearchString) ||
          order.guestId?.toLowerCase()?.includes(lowerCaseSearchString)
        );
      });

      setFilteredOrders(filteredOrders);
    },
    [orders],
  );

  const handleExport = () => {
    const dataToExport = filteredOrders.map(order => ({
      ID: order.id,
      [t('status')]: order.requestStatus,
      [t('requested')]: order.requestedDate,
      [t('order_name')]: order.name,
      [t('quantity')]: 'n/a',
      [t('price')]: order.totalPrice,
      [t('unit')]: order.unitName,
      [t('unit_id')]: order.unitId,
      [t('guest_name')]: order.guestName,
      [t('guest_id')]: order.guestId,
    }));

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [
        `ID,${t('status')},${t('requested')},${t('order_name')},${t('order_name')},${t(
          'price',
        )},${t('unit')},${t('unit_id')},${t('guest_name')},${t('guest_id')}`,
      ]
        .concat(dataToExport.map(row => Object.values(row).join(',')))
        .join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'orders_export.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columnsDef: GridColDef[] = [
    {
      field: 'requestStatus',
      headerName: t('status'),
      minWidth: 120,
      renderCell: ({ row }: GridRenderCellParams<Order>) => (
        <Box
          sx={{
            backgroundColor: getStatusColor(row.requestStatus),
            color: setColor(row.requestStatus),
            paddingVertical: '5px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderRadius: '5px',
            fontWeight: 600,
            fontSize: '10px',
          }}
          data-testid='order-request-status'
        >
          {getDisplayStatus(row.requestStatus)}
        </Box>
      ),
      flex: 1,
    },
    {
      field: 'requestedDate',
      headerName: t('requested'),
      minWidth: 120,
      renderCell: ({ row }: GridRenderCellParams<Order>) => (
        <Box sx={{ background: 'white' }}>
          <Typography variant='body2' noWrap>
            {format(new Date(row.requestedDate), 'yyyy-MM-dd')}
          </Typography>
          <Typography variant='caption' color='textSecondary' noWrap>
            {format(new Date(row.requestedDate), 'hh:mm aa')}
          </Typography>
        </Box>
      ),
      flex: 2,
      sortable: true,
    },
    {
      field: 'name',
      headerName: t('order'),
      minWidth: 250,
      renderCell: ({ row }: GridRenderCellParams<Order>) => (
        <Box
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', background: 'white' }}
          onClick={() => setSelectedOrder(row)}
          data-testid='order-name'
        >
          <Avatar
            src={row.imageUrl}
            alt={row.name}
            variant='rounded'
            sx={{ marginRight: '10px', borderRadius: '8px' }}
          />
          <Box>
            <Typography
              variant='body2'
              noWrap
              sx={{
                color: 'primary.main',
                fontWeight: 600,
                cursor: 'pointer',
              }}
            >
              {row.name}
            </Typography>
            <Typography variant='caption' color='textSecondary' noWrap>
              {row.id}
            </Typography>
          </Box>
        </Box>
      ),
      flex: 3,
    },
    {
      field: 'pricing.maxQuantity',
      headerName: t('quantity'),
      minWidth: 92,
      flex: 1,
      sortable: true,
      cellClassName: 'white-cell',
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'priceSticker',
      headerName: t('price'),
      minWidth: 150,
      flex: 1,
      sortable: true,
      renderCell: ({ row }: GridRenderCellParams<Order>) => (
        <Box sx={{ background: 'white' }}>{`$${row.totalPrice.toFixed(2)} USD`}</Box>
      ),
      cellClassName: 'white-cell',
      maxWidth: 50,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'unitAssigned.units',
      headerName: t('unit'),
      minWidth: 235,
      renderCell: ({ row }: GridRenderCellParams<Order>) => (
        <Box sx={{ background: 'white' }}>
          <Typography variant='body2' noWrap>
            {row.unitName}
          </Typography>
          <Typography variant='caption' color='textSecondary' noWrap>
            {row.unitId}
          </Typography>
        </Box>
      ),
      flex: 2,
      sortable: true,
    },
    {
      field: 'guestName',
      headerName: t('guest'),
      minWidth: 235,
      renderCell: ({ row }: GridRenderCellParams<Order>) => (
        <Box sx={{ background: 'white' }}>{row.guestName}</Box>
      ),
      flex: 2,
      sortable: true,
    },
  ];

  useEffect(() => {
    if (orders.length) {
      setFilteredOrders(orders);
    }
  }, [orders]);

  useEffect(() => {
    if (!!companyRequests) {
      // Sort orders by requestedDate in descending order
      const sortedRequests = [...companyRequests.data].sort(
        (a: Order, b: Order) =>
          new Date(b.requestedDate).getTime() - new Date(a.requestedDate).getTime(),
      );
      setOrders(sortedRequests);
    }
  }, [company.id, companyRequests]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flex: 1, height: 400, width: '100%' }}>
        <PaginatedTable
          enableToolbar
          title={title}
          rows={filteredOrders}
          rowCount={filteredOrders.length}
          columns={columnsDef}
          loading={isLoading}
          onFetch={handleSearch}
          onExport={handleExport}
          sx={{ '& .MuiDataGrid-cell': { backgroundColor: 'white' } }}
        />
      </Box>
      {selectedOrder && (
        <OrderDetailsSidebar
          order={selectedOrder}
          onClose={() => setSelectedOrder(null)}
          onUpdate={onUpdate}
        />
      )}
    </Box>
  );
};

export default OrdersTable;
