import { ErrorOutlineOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react';

export default function ErrorMessageBanner({ message }: { message: string }) {
  return (
    <Stack sx={rootContainerStyles}>
      <ErrorOutlineOutlined style={errorMessageIconStyles} />
      <Typography sx={errorMessageTypographyStyles}>{message}</Typography>
    </Stack>
  );
}

const rootContainerStyles = {
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: '#FBEAEB',
  color: '#541313',
  height: '52px',
  padding: '6px 16px',
};

const errorMessageTypographyStyles = {
  '&::first-letter': { textTransform: 'uppercase' },
  fontWeight: 500,
};

const errorMessageIconStyles = { fontSize: '40px', padding: '7px 12px 7px 0', color: '#D32F2F' };
