import { EditOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Tag, getTagsByMemberId } from '@operto/tags-shared';
import { trpc } from '@operto/trpc-client';
import { useAppFeatures } from 'lib/app-features';
import { MemberFilterType } from 'member/memberType';
import React, { useCallback, useState } from 'react';
import { Group } from 'redux/groups/types';
import { INITIAL_STATE, useGetMembersQuery } from 'redux/members/api-slice';
import { Member } from 'redux/members/types';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import { TableCell } from 'ui-library/Components/table/TableCell';
import {
  editButtonStyle,
  paginatedTableStyle,
  searchToolbarStyle,
} from 'ui-library/Components/table/table-styles';
import { NamedChip } from '../Common/NamedChip';
import { GroupMembersEditForm } from '../EditGroup/GroupMembersEditForm';

type GroupDetailsTableProps = {
  group: Group;
};

export const GroupMembersTable = ({ group }: GroupDetailsTableProps) => {
  const { isFeatureEnabled } = useAppFeatures();
  const {
    data: { members } = INITIAL_STATE,
    isLoading,
    isFetching,
  } = useGetMembersQuery({
    filterType: MemberFilterType.GROUP,
    numPerPage: 100000,
    [MemberFilterType.GROUP]: group?.id,
  });

  const { data: companyTags = [] } = trpc.tags.getTags.useQuery();

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>();
  const filteredList = members.filter(
    member => !searchValue || member.name?.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const onEditClick = () => {
    setIsEditFormOpen(true);
  };

  const onClose = () => {
    setIsEditFormOpen(false);
  };

  const renderEditButton = () => {
    return (
      <Button sx={editButtonStyle} startIcon={<EditOutlined />} onClick={onEditClick}>
        Edit
      </Button>
    );
  };

  const handleFetch = useCallback((_pageNum: number, _numPerPage: number, searchValue?: string) => {
    setSearchValue(searchValue);
  }, []);

  const membersColumnsDef: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Members',
      width: 300,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <TableCell title={params.row.name} description={params.row.email} />
      ),
    },
    isFeatureEnabled('tags') && {
      field: 'tags',
      headerName: 'Member Tags',
      width: 300,
      sortable: false,
      renderCell: ({ row: member }: GridRenderCellParams<unknown, Member>) => {
        const memberTags = getTagsByMemberId(member.id, companyTags);
        return <NamedChip names={memberTags?.map((tag: Tag) => tag.label)} />;
      },
    },
  ];

  return (
    <>
      <PaginatedTable
        enableToolbar
        title={renderEditButton()}
        loading={isLoading || isFetching}
        rows={filteredList}
        columns={membersColumnsDef}
        onFetch={handleFetch}
        sortingMode='server'
        searchToolbarSx={searchToolbarStyle}
        sx={paginatedTableStyle}
      />
      <GroupMembersEditForm group={group} onClose={onClose} open={isEditFormOpen} />
    </>
  );
};
