import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { CHECK_IN, CHECK_OUT, FilterGroup, SCHEDULED, dateRangeKeys } from 'types/filter-type';

/**
 * persistFilters : save the settings to whatever storage we are using
 * hydrateFilters : get the settings from whatever storage we are using
 */
type UseSyncFiltersProps<T> = {
  key?: string;
  persistFilters: (key: string, settings: T) => void;
  hydrateFilters: (key: string) => T | undefined;
};

export function useSyncFilters<SyncFilters extends URLSearchParams>({
  key,
  persistFilters,
  hydrateFilters,
}: UseSyncFiltersProps<SyncFilters>) {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterGroups, setFilterGroups] = useState<FilterGroup[]>();
  const syncStatusRef = useRef<'unsynched' | 'synching' | 'synched'>('unsynched');

  const syncFilters = useCallback(
    (settings: SyncFilters) => {
      if (key?.length) {
        setSearchParams(settings);
        persistFilters(key, settings);
      }
    },
    [key, setSearchParams, persistFilters],
  );

  const setSyncStatus = useCallback(
    (status: 'unsynched' | 'synching' | 'synched') => (syncStatusRef.current = status),
    [],
  );

  const addFilterGroups = useCallback(
    (filterGroups: FilterGroup[]) => {
      const refinedSearchParam = new URLSearchParams(searchParams);
      const validatedGroupList = new Set<string>();
      const validatedValues: Record<string, string[]> = {};

      // setup validatedGroupList and validatedValues
      filterGroups.forEach(filterGroup => {
        if (filterGroup.groupName === 'devices') {
          filterGroup.options.forEach(option => {
            validatedGroupList.add(option.name);
            const values: string[] = [];
            option.slideDownData.forEach(data => {
              values.push(data.name);
            });
            validatedValues[option.name] = values;
          });
        } else if (
          filterGroup.groupName === CHECK_IN ||
          filterGroup.groupName === CHECK_OUT ||
          filterGroup.groupName === SCHEDULED
        ) {
          validatedGroupList.add(`${filterGroup.groupName}_from`);
          validatedGroupList.add(`${filterGroup.groupName}_until`);
        } else {
          const values: string[] = [];
          validatedGroupList.add(filterGroup.groupName);
          filterGroup.options.forEach(option => {
            values.push(option.name);
          });
          validatedValues[filterGroup.groupName] = values;
        }
      });

      // remove invalid keys
      for (const key of searchParams.keys()) {
        if (!validatedGroupList.has(key)) {
          refinedSearchParam.delete(key);
        }
      }

      // remove both from and until keys if only one is present
      for (const key of dateRangeKeys) {
        const secondKey = key.includes('_from')
          ? key.replace('_from', '_until')
          : key.replace('_until', '_from');
        if (searchParams.has(key) && !searchParams.has(secondKey)) {
          refinedSearchParam.delete(key);
        }
      }

      const updatedSearchParams = new URLSearchParams(refinedSearchParam);

      // remove invalid values
      for (const [key, value] of refinedSearchParam.entries()) {
        if (dateRangeKeys.includes(key)) {
          const isValidDate = value.match(/^\d{4}-\d{2}-\d{2}$/);
          if (!isValidDate) {
            updatedSearchParams.delete(key);
            const secondKey = key.includes('_from')
              ? key.replace('_from', '_until')
              : key.replace('_until', '_from');
            updatedSearchParams.delete(secondKey);
          }
        } else {
          const values = value.split(',').filter(val => validatedValues[key].includes(val));
          if (values.length > 0) {
            updatedSearchParams.set(key, values.join(','));
          } else {
            updatedSearchParams.delete(key);
          }
        }
      }

      setFilterGroups(filterGroups);
      setSearchParams(updatedSearchParams);
      setSyncStatus('synched');
    },
    [searchParams, setSearchParams, setSyncStatus],
  );

  useEffect(() => {
    if (key?.length && !searchParams.toString()?.length) {
      const settings = hydrateFilters(key);
      setSearchParams(settings);
    }
  }, [key, setSearchParams, hydrateFilters, searchParams, pathname]);

  return {
    searchParams,
    filterGroups,
    syncStatus: syncStatusRef.current,
    setSyncStatus,
    syncFilters,
    addFilterGroups,
  };
}
