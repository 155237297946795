import Stripe from 'assets/img/integrations/stripe.svg';
import React, { useEffect, useState } from 'react';
import { IntegrationsCard, IntegrationsCardActionType } from '../IntegrationsCard';
import { useSelector } from 'react-redux';
import { companySelector } from 'company/state/companySelectors';
import {
  createStripeConnectAccount,
  createStripeConnectAccountLink,
  getStripeConnectAccountData,
  retrieveStripeConnectAccount,
} from 'api/paymentAPI';

const STRIPE_INFO_URL = `${process.env.REACT_APP_CLIENT_SUPPORT_URL}/s/article/stripe-connect`;

export const StripeConnectIntegrationCard = () => {
  const company = useSelector(companySelector());
  const companyId: string = company.id.toString();
  const [isConnected, setIsConnected] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  const getActionTitle = () => {
    if (isConnected && !isEnabled) {
      return 'EDIT';
    }

    return undefined;
  };

  const openStripeAccountLink = async (stripeAccountId: string) => {
    const accountLinkResponse = await createStripeConnectAccountLink(stripeAccountId);
    const url = accountLinkResponse.data.data.url;
    window.open(url, '_self');
  };

  const handleIntegration = async () => {
    const createAccountResponse = await createStripeConnectAccount(companyId);
    const stripeAccountId = createAccountResponse.data.data;

    openStripeAccountLink(stripeAccountId);
  };

  const handleEdit = async () => {
    const accountDataResponse = await getStripeConnectAccountData(companyId);
    const stripeAccountId = accountDataResponse.data.data.accountId;

    openStripeAccountLink(stripeAccountId);
  };

  const handleActionClick = (action: IntegrationsCardActionType) => {
    switch (action) {
      case 'CONNECT':
        handleIntegration();
        break;
      case 'EDIT':
        handleEdit();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      let stripeAccountId: string;
      const response = await getStripeConnectAccountData(companyId);
      if (response.data.data != null) {
        stripeAccountId = response.data.data.accountId;
        setIsConnected(stripeAccountId != null);
      }

      if (stripeAccountId != null) {
        const response = await retrieveStripeConnectAccount(stripeAccountId);
        const requirmentsDue = response.data.data;

        if (!requirmentsDue) {
          setIsEnabled(true);
        }
      }
    };

    checkOnboardingStatus();
  });

  return (
    <>
      <IntegrationsCard
        title='Stripe Connect'
        connected={isConnected}
        enabled={isEnabled}
        actionTitle={getActionTitle()}
        onInfoClick={() => window.open(STRIPE_INFO_URL, '_blank')}
        onActionClick={handleActionClick}
      >
        <Stripe />
      </IntegrationsCard>
    </>
  );
};
