import { Box, Switch, Typography } from '@mui/material';
import { AddCard } from '@operto/ui-library';
import useTranslation from 'hooks/useTranslation';
import React, { ChangeEvent } from 'react';
import renderTextField from './TextInput';

interface PreferredTimeProps {
  header: string;
  active: boolean;
  setHeader: (header: string) => void;
  setActive: (active: boolean) => void;
}

export const PreferredTime = ({ header, active, setHeader, setActive }: PreferredTimeProps) => {
  const handlePreferedTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHeader(event.target.value);
  };

  const { t } = useTranslation();

  return (
    <AddCard
      title={
        <Box display='flex' flexDirection='column' justifyContent='space-between'>
          <Typography variant='subtitle1'>{t('time_picker')}</Typography>
          <Typography variant='body2'>{t('body_text_for_time_picker')}</Typography>
        </Box>
      }
      action={<Switch checked={active} onChange={() => setActive(!active)} />}
    >
      {active && (
        <Box display='flex' flexDirection='column' gap={2}>
          <Typography>{t('guest_can_choose_their_preferred_time_through_out_the_day')}</Typography>
          {renderTextField(
            t('time_header'),
            t('preferred_time'),
            header,
            handlePreferedTimeChange,
            50,
            true,
            1,
            false,
            1,
            1,
          )}
        </Box>
      )}
    </AddCard>
  );
};
